import React, { useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Switch from "components/common/Switch/Switch";
import TextField from "components/common/TextField/TextField";
import SubmitButton from "components/common/Button/SubmitButton";
import useIntegrationSectionState from "hooks/integration/useIntegrationSectionState";
import ServiceDeskIntegration from "../../../../../types/model/settings/integration/ServiceDeskIntegration";

interface ServiceDeskIntegrationProps {
    integration: ServiceDeskIntegration;
    onSave: (integration: ServiceDeskIntegration) => void;
}
interface ErrorMessages {
    baseUrl: string | null;
    username: string | null;
    apiToken: string | null;
    serviceDeskId: string | null;
    requestTypeId: string | null;
}
const ServiceDeskIntegrationSection: React.FC<ServiceDeskIntegrationProps> = ({
                                                                                onSave,
                                                                                ...props
                                                                            }) => {
    const { integration, handleChangeProperty, handleEnabledToggle }
        = useIntegrationSectionState(props.integration);
    const {enabled, baseUrl, username,
        apiToken, serviceDeskId, requestTypeId}
        = integration as ServiceDeskIntegration;
    const [errors, setErrors] = useState<ErrorMessages>({
        baseUrl: null,
        username: null,
        apiToken: null,
        serviceDeskId: null,
        requestTypeId: null,
    });
    const handleSave = () => {
        let hasError = false;
        const newErrors: ErrorMessages = { baseUrl: null, username: null, apiToken: null, serviceDeskId: null, requestTypeId: null };

        if (enabled) {
            if (!baseUrl) {
                newErrors.baseUrl = "BaseUrl cannot be empty.";
                hasError = true;
            }
            if (!username) {
                newErrors.username = "Username cannot be empty.";
                hasError = true;
            }
            if (!apiToken) {
                newErrors.apiToken = "API Token cannot be empty.";
                hasError = true;
            }
            if (!serviceDeskId) {
                newErrors.serviceDeskId = "ServiceDesk Id cannot be empty.";
                hasError = true;
            }
            if (!requestTypeId) {
                newErrors.requestTypeId = "Request Type Id cannot be empty.";
                hasError = true;
            }
            setErrors(newErrors);
        }
        if (!hasError) {
            newErrors.baseUrl = "";
            setErrors(newErrors);
            onSave(integration as ServiceDeskIntegration);
        }
    };
    return (
        <>
            <Grid
                container
                spacing={2}
                direction="column"
                justifyContent="flex-start"
                style={{marginTop: "8px", marginBottom: "8px"}}
            >
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="enable">Enabled</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <Switch checked={enabled || false}
                                onChange={handleEnabledToggle}
                                name="enabled"
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="baseUrl">Base URL</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            disabled={!enabled}
                            name="baseUrl"
                            fullWidth
                            textAlign="left"
                            value={baseUrl}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                        {enabled && errors.baseUrl && <div style={{ color: 'red', marginTop: '4px', textAlign: 'left' }}>{errors.baseUrl}</div>}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="username">Username</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            disabled={!enabled}
                            name="username"
                            fullWidth
                            textAlign="left"
                            value={username}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                        {enabled && errors.username && <div style={{ color: 'red', marginTop: '4px', textAlign: 'left' }}>{errors.username}</div>}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="apiToken">API Token</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            disabled={!enabled}
                            name="apiToken"
                            fullWidth
                            textAlign="left"
                            value={apiToken}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                        {enabled && errors.apiToken && <div style={{ color: 'red', marginTop: '4px', textAlign: 'left' }}>{errors.apiToken}</div>}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="serviceDeskId">ServiceDesk ID</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            disabled={!enabled}
                            name="serviceDeskId"
                            fullWidth
                            textAlign="left"
                            value={serviceDeskId}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                        {enabled && errors.serviceDeskId && <div style={{ color: 'red', marginTop: '4px', textAlign: 'left' }}>{errors.serviceDeskId}</div>}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="requestTypeId">Request Type ID</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            disabled={!enabled}
                            name="requestTypeId"
                            fullWidth
                            textAlign="left"
                            value={requestTypeId}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                        {enabled && errors.requestTypeId && <div style={{ color: 'red', marginTop: '4px', textAlign: 'left' }}>{errors.requestTypeId}</div>}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={7}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center">
                    <Grid item xs={1}>
                        {/*@ts-ignore*/}
                        <SubmitButton onClick={handleSave}>Save</SubmitButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ServiceDeskIntegrationSection;

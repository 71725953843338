import {BASE_API_URL} from "../../config";
import CommonCrudRestClient from "./CommonCrudRestClient";

class UpdatesRestClient extends CommonCrudRestClient {

    createDownloadFileLink(fileName: string) {
        console.log("downloadFile fileName: " + fileName);
        return this.location + `/download?fileName=${fileName}`;
    }
}

export default new UpdatesRestClient(`${BASE_API_URL}/infrastructure/updates`);

import React, {Component} from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import {Button, Grid, MenuItem, Select} from "@material-ui/core";
import GateNavBarContainer from "containers/common/NavBarContainer";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import DropdownMenu from "components/common/DropdownMenu/DropdownMenu";
import DropdownMenuItem from "components/common/DropdownMenu/DropdownMenuItem";
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import StompClient from "../../../websocket/StompClient";
import {STOMP_CLIENT} from "../../../reducers/webSocketSlice";
import {HLC_WEB_SOCKET_URL, IS_HLC_WS_ENABLED, IS_PEI_WS_ENABLED, PEI_WEB_SOCKET_URL} from "config";
import PEIChartBagWebSocketSubscription from 'dashlets/PEIChartBagDashlet/components/PEIChartBagWebSocketSubscription';
import PEIDashletsRestClient from 'api/rest/PEIDashletsRestClient';

const styles = theme => ({
    root: {
      height: '100%',
    },
    section: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'calc(100% - 72px)',
      textAlign: 'center',
    },
    wrapper: {
      width: '30rem',
      display: 'inline-block',
    },
    leftIcon: {
      marginRight: theme.spacing(1),
      opacity: .2,
    },
  
    notchedOutline: {
      borderRadius: '2rem',
      borderColor: '#fff !important'
    },
  
    cssLabel: {
      color: '#fff'
    },
    cssFocused: {
      color: '#fff !important',
    },
    cssError: {
      color: 'red !important',
    },
    passwordInput: {
      color: '#fff',
    },
  
  
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    hint: {
      marginTop: theme.spacing(1)
    },
    progress: {
      margin: theme.spacing(2),
    },
  
});
const PasswordButtons = withStyles(theme => ({
    root: {
      marginTop: theme.spacing(3),
      marginLeft: '33px',
      background: theme.palette.primary.main,
  
      '&:hover': {
        background: 'rgba(80, 137, 179, 0.95)'
      }
    },
    label: {
      color: theme.palette.secondary.contrastText,
    }
}))(Button);
const ProfileNavBarButton = withStyles(theme => ({
  root: {
  },
  label: {
    color: 'black',
    textTransform: 'none',
    marginTop: '6px'
  }
}))(Button);
class AddEmailConfiguration extends Component {
    constructor(props) {
        super(props)
        this.state = { matches: window.matchMedia("(min-width: 768px)").matches,
        anchorEl: null, 
        textValue: null,
        senderEmail : this.props.senderEmail || "",
        senderPassword: this.props.senderPassword, 
        host: this.props.host, 
        emailConfigurationType: this.props.emailConfigurationType, emailPort: this.props.emailPort, isShowPassword: false, isLocked: this.props.isFormLocked, 
        backends: [],
        backendKey: ""
      };
          
      PEIDashletsRestClient.listUserBackends()
          .then(backends => this.setBackends(backends))
          .catch(error => console.error(error))
    }

    setBackends = (backends) => {
      this.setState({backends: backends});
    }

    onChangeBackend = (key) => {
      this.setState({backendKey: key});
      this.props.getEmailConfigurationResult(key);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if(prevProps.senderEmail !== this.props.senderEmail){
        this.setState({emailPort : this.props.emailPort,
          senderEmail : this.props.senderEmail,
          senderPassword: this.props.senderPassword, 
          host: this.props.host, 
          emailConfigurationType: this.props.emailConfigurationType});
      }
      if (this.state.isLocked != this.props.isFormLocked) {
        this.setState({isLocked: this.props.isFormLocked });
      }
    }

    lockForm = () => {
      this.setState({isLocked: true});
    };

    unlockForm = () => {
      this.setState({isLocked: false});
    };

    setAnchorEl = (anchorEl) => {
      this.setState({
        anchorEl: anchorEl
      });
    };

    onClickActionsHandler = (e) => {
      e.stopPropagation();
      this.setAnchorEl(e.currentTarget);
    };
  
    closeActions = () => {
      this.setAnchorEl(null);
    };
    onClickAddDashboardHandler = (e) => {
      e.stopPropagation();
      this.setState({
        textValue: e.target.outerText
      });
      this.props.changeConfigurationType(e.target.outerText);
      this.closeActions();
    };
    changePort = (value) => {
      this.setState({
        emailPort : value
      });
      this.props.changePort(value);
    };
    setEmailId = (value) => {
      this.setState({
        senderEmail : value
      });
      this.props.changeEmailId(value);
    };
    setPassword = (value) => {
      this.setState({
        senderPassword : value
      });
      this.props.changePassword(value);
    };
    setHost = (value) => {
     this.setState({
        host : value
     });
     this.props.changeHost(value);
    };
    getEmailConfigObject() {
        return {
            senderEmail: this.state.senderEmail,
            emailConfigurationType: this.props.emailConfigurationType,
            emailPort: this.props.emailPort,
            senderPassword: this.state.senderPassword,
            domainName: this.state.host,
        }
    }
    onSaveClickHandler(isOnSaveClick) {
        var emailConfig = this.getEmailConfigObject();
        if(this.props.setEmailConfiguration && isOnSaveClick) {
            this.props.setEmailConfiguration(emailConfig, this.state.backendKey);
        } else if (this.props.onTestClick && !isOnSaveClick) {
            this.props.onTestClick(emailConfig, this.state.backendKey);
        }
    }
    render() {
        const {
            onHideClick,
            changeConfigurationType,
            changePort,
          } = this.props;
        const {anchorEl, textValue} = this.state;
        const {classes, onChangeInputHandler, onSubmit, errors, isLocked } = this.props;
        let defaultPort = '';
        if(!this.props.emailPort) {
            defaultPort = this.props.emailConfigurationType == 'Secure (TLS)' ? '587' : '465';
        }
        return(
        <div className={classes.root}>
            <GateNavBarContainer />
            <section className={classes.section}>
            <div className={classes.wrapper}>
            <Paper className={classes.paper}>
              <Typography component="h1" variant="h5">
              Performance Explorer Email Configuration
              </Typography>
              <form className={classes.form} id='emailForm'>
                <Grid
                  item
                  container
                  spacing={2}
                  xs={12}
                  direction="row"
                  alignItems="center">
                  <Grid item xs={3} style={{ textAlign: "left" }}>
                    <InputLabel htmlFor="enable">Backend</InputLabel>
                  </Grid>
                  <Grid item xs={5} style={{ paddingRight: "12px" }}>
                    <Select style={{ width: "100%" }} value={this.state.backendKey} name="intergationsBackendKey"
                      onChange={(event) =>
                        this.onChangeBackend(event?.target?.value)}
                    >
                      {this.state.backends.map(backend =>
                        <MenuItem
                          data-testid={`integration-backend-dropdown-option-${backend.backendKey}`}
                          key={`integration-backend-menu-item-${backend.backendKey}`}
                          value={backend.backendKey}
                        >
                          {backend.name}
                        </MenuItem>
                      )}
                    </Select>
                  </Grid>
                </Grid>
                <FormControl margin="normal" required fullWidth >
                  <InputLabel htmlFor="emailType">Select type</InputLabel>
                </FormControl>
                <ProfileNavBarButton
                  variant="text"
                  // aria-owns={isProfileMenuOpen ? 'material-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.onClickActionsHandler}
                >
                    {this.props.emailConfigurationType ? this.props.emailConfigurationType : 'Secure (SSL)'}
                <ArrowDropDown fontSize="medium" className={classes.icon}/>
                  </ProfileNavBarButton>
                    <DropdownMenu
                        anchorEl={anchorEl}
                        onClose={this.handleActionsClose}
                        >
                      <DropdownMenuItem text="Secure (SSL)" onClick={this.onClickAddDashboardHandler}/>
                      <DropdownMenuItem text="Secure (TLS)" onClick={this.onClickAddDashboardHandler}/>
                    </DropdownMenu>
                  
                  <FormControl margin="normal" required fullWidth >
                    <InputLabel htmlFor="port">Port </InputLabel>
                    <Input id="port" name="port" type="text" value={this.state.emailPort} onChange={(event) => this.changePort(event.currentTarget.value)} disabled={isLocked}/>
                  </FormControl>
                  
                  <FormControl margin="normal" required fullWidth >
                    <InputLabel htmlFor="emailId" filled={this.state.senderEmail ? true : false} shrink={this.state.senderEmail ? true : false} >Email Id  </InputLabel>
                    <Input id="emailId" name="emailId" type="text" value={this.state.senderEmail} onChange={(event) => this.setEmailId(event.currentTarget.value)} disabled={isLocked}/>
                  </FormControl>
                  <FormControl margin="normal" required fullWidth >
                    <InputLabel htmlFor="Password" filled={this.state.senderPassword ? true : false} shrink={this.state.senderPassword ? true : false} >Password </InputLabel>
                    <Input id="Password" name="Password" type="password" value={this.state.senderPassword} onChange={(event) => this.setPassword(event.currentTarget.value)} disabled={isLocked}/>
                  </FormControl>
                  <FormControl margin="normal" required fullWidth >
                    <InputLabel htmlFor="server" filled={this.state.host ? true : false} shrink={this.state.host ? true : false} >Host  </InputLabel>
                    <Input id="server" name="server" type="text" onChange={(event) => this.setHost(event.currentTarget.value)} value={this.state.host} disabled={isLocked}/>
                  </FormControl>
                <PasswordButtons
                  // fullWidth
                  variant="contained"
                  onClick={() => this.onSaveClickHandler(true)}
                  disabled={isLocked}
                >Save</PasswordButtons>
                <PasswordButtons
                  // fullWidth
                  variant="contained"
                  onClick={onHideClick}
                  disabled={isLocked}
                >Cancel</PasswordButtons>
                <PasswordButtons
                  // fullWidth
                  variant="contained"
                  onClick={() => this.onSaveClickHandler(false)}
                  disabled={this.state.isLocked}
                >Test email</PasswordButtons>
              </form>
            </Paper>
            </div>
            </section>
            <PEIChartBagWebSocketSubscription />
            {IS_PEI_WS_ENABLED ? [
            <StompClient
                key="hlc-ws-stomp-client-1582840873831"
                stompClientKey={STOMP_CLIENT.PEI}
                sockJsEndpoint={PEI_WEB_SOCKET_URL}
                debugMode
            />,
          ] : null}
          {IS_HLC_WS_ENABLED ? [
            <StompClient
                key="pei-ws-stomp-client-1582840873831"
                stompClientKey={STOMP_CLIENT.HLC}
                sockJsEndpoint={HLC_WEB_SOCKET_URL}
                debugMode
            />,
          ] : null}
        </div>
    );
    }
}
AddEmailConfiguration.propTypes = {
    //data
    senderEmail: PropTypes.string,
    senderPassword: PropTypes.string,
    host: PropTypes.string,
    emailConfigurationType: PropTypes.string,
    emailPort: PropTypes.string,
    //func
    onHideClick: PropTypes.func,
    setEmailConfiguration: PropTypes.func,
    onTestClick:PropTypes.func,
    changeConfigurationType: PropTypes.func,
    changePort: PropTypes.func,
    getEmailConfigurationResult: PropTypes.func,
};
export default withStyles(styles)(AddEmailConfiguration);
import * as EmailConfigurationTypes from 'actions/dashboard/EmailConfigurationTypes';
import { getEmailConfiguration, getEmailConfigurationPEI, saveEmailConfiguration, saveEmailConfigurationForPEI, testEmailConfigurationPEI, testEmailConfigurationHLC } from 'views/gate/AddEmailConfiguration/webSocketClient';
import { routeTo } from './routingActions';
import {HOME_PATH} from '../routes/paths';
import { toastr } from 'react-redux-toastr';
import { EMAIL_URL } from 'config';

export const STOMP_CLIENT = {
    PEI: "PEI",
    HLC: "HLC"
};

export function getEmailConfigurationData() {
    return (dispatch, getState) => {
        let state = getState();
        let backendKey;
        if(state.webSocket.stompClients[STOMP_CLIENT.PEI] && state.webSocket.stompClients[STOMP_CLIENT.PEI].connected) {
            backendKey = state.webSocket.peiActiveBackendKey;
            getEmailConfigurationPEI(getState(), backendKey, {});
        }
    }
}

export function closeEmailConfigurationModal() {
    return(dispatch, getState) => {
        dispatch(routeTo(HOME_PATH))
    }
}
export function validateEmailConfiguration(emailConfig) {
    let senderEmail = emailConfig.senderEmail;
    let pattern = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    let errorObject = { portError: false};
    if(emailConfig.emailPort || emailConfig.emailPort == 0) {
        errorObject.portError = emailConfig.emailPort <= 0 || emailConfig.emailPort > 65535; 
    }
    errorObject.commonError = !(pattern.test(senderEmail) && emailConfig.senderPassword != '' && emailConfig.host != '');
    errorObject.hasAnyError = errorObject.portError || errorObject.commonError;
    return errorObject;
}
export function setEmailConfiguration(emailConfig, backendKey) {
    return(dispatch,getState) => {
        const errorObject = validateEmailConfiguration(emailConfig);
        if(!errorObject.hasAnyError) {
            if(emailConfig.emailPort == null) {
                if (emailConfig.emailConfigurationType == 'Secure (TLS)') {
                    emailConfig.emailPort = 587;
                } else {
                    emailConfig.emailPort = 465;
                }
            }

            dispatch(saveEmailConfigurationAPI(emailConfig, backendKey));
            dispatch(saveEmailConfigData(emailConfig));
        } else {
            if(errorObject.portError) {
                toastr.error("Port number must be between [1-65535] range.");
            } else if(errorObject.commonError) {
                toastr.error("Please fill up all fields or enter valid email id.");
            }
        }
    }
}

export function saveEmailConfigurationAPI(emailConfig, backendKey) {
    return(dispatch, getState) => {
        dispatch(routeTo(HOME_PATH));
        setTimeout(() => dispatch(setEmailConfigAPICall(emailConfig, backendKey)), 500);
    }
}

export function sendTestEmailToUser(emailConfig, backendKey) {
    return(dispatch,getState) => {
        const state = getState();
        const errorObject = validateEmailConfiguration(emailConfig);
        let isPEIConnected = state.webSocket.stompClients[STOMP_CLIENT.PEI] && state.webSocket.stompClients[STOMP_CLIENT.PEI].connected;
        if (!errorObject.hasAnyError) {
            // let backendKeyPEI = state.webSocket.peiActiveBackendKey;
            if(isPEIConnected) {
                toastr.info("We trying to connect with host to send the email it may take some time.");
                testEmailConfigurationPEI(getState(), backendKey, { emailConfig: emailConfig});
            }
        } else {
            if(errorObject.portError) {
                toastr.error("Port number must be between [1-65535] range.");
            } else if(errorObject.commonError) {
                toastr.error("Please fill up all fields or enter valid email id.");
            }
        }
    }
}

export function setEmailConfigAPICall(emailConfiguration, backendKey) {
    return (dispatch, getState) => {
        const state = getState();
        let isPEIConnected = state.webSocket.stompClients[STOMP_CLIENT.PEI] && state.webSocket.stompClients[STOMP_CLIENT.PEI].connected;
        if(isPEIConnected) {
            saveEmailConfigurationForPEI(state, backendKey, {emailConfig: emailConfiguration} );
        }
    }
}

export function getEmailConfigurationResult(backendKey) {
    return (dispatch,getState) => {
        let state = getState();
        let backendKeyPEI = state.webSocket.stompClients[STOMP_CLIENT.PEI] && state.webSocket.stompClients[STOMP_CLIENT.PEI].connected;
        if(backendKeyPEI) {
            getEmailConfigurationPEI(getState(), backendKey, {});
        }
    }
}

export function handleGetEmailConfigurationResult(emailConfig) {
    return (dispatch,getState) => {
        dispatch(saveEmailConfigData(emailConfig));
    }
}

export function saveEmailConfigurationResult(response) {
    return (dispatch,getState) => {
        if(response.hasErrors) {
            toastr.error(response.errorMessage);
        } else {
            toastr.success(response.result);
            dispatch(closeEmailConfigurationModal());
        }
    }
}

export function handleEmailClick() {
    return (dispatch,getState) => {
        const state = getState();
        let peiConnected = state.webSocket.stompClients[STOMP_CLIENT.PEI] && state.webSocket.stompClients[STOMP_CLIENT.PEI].connected;
        if(peiConnected) {
            window.open(EMAIL_URL, "_self")
        } else {
            toastr.error("PEI Backend is not connected");
        }
    }
}

export function testEmailConfigurationResultHLC(response) {
    return (dispatch, getState) => {
        if(response.hasErrors){
            toastr.error(response.errorMessage);
        } else {
            toastr.success(response.result);
        }
    }
}

export function testEmailConfigurationResultPEI(response) {
    return (dispatch, getState) => {
        if(response.hasErrors){
            toastr.error(response.errorMessage);
        } else{
            toastr.success(response.result);
        }
    }
}

export function saveEmailConfigData(emailConfig) {
    return {
        type: EmailConfigurationTypes.SET_EMAIL_CONFIGURATION,
        emailConfig
    }
}

export function resetEmailConfigurationState() {
    return {
        type: EmailConfigurationTypes.RESET_EMAIL_CONFIGURATION_STATE
    }
}

export function setEmailConfigurationState(state) {
    return {
        type: EmailConfigurationTypes.SET_EMAIL_CONFIGURATION_STATE,
        state
    }
}
export function changeConfigurationType(value) {
    return {
        type: EmailConfigurationTypes.CHANGE_EMAIL_CONFIGURATION_TYPE,
        value
    }
}
export function changePort(value) {
    return {
        type: EmailConfigurationTypes.CHANGE_EMAIL_PORT,
        value
    }
}
export function changeEmailID(value) {
    return {
        type: EmailConfigurationTypes.CHANGE_EMAIL_ID,
        value
    }
}
export function changePassword(value) {
    return {
        type: EmailConfigurationTypes.CHANGE_EMAIL_PASSWORD,
        value
    }
}
export function changeHost(value) {
    return {
        type: EmailConfigurationTypes.CHANGE_EMAIL_HOST,
        value
    }
}
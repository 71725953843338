import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "components/common/TextField/TextField";
import SubmitButton from "components/common/Button/SubmitButton";
import useIntegrationSectionState from "hooks/integration/useIntegrationSectionState";
import SnowflakeIntegration from "types/model/settings/integration/SnowflakeIntegration";
import Switch from "components/common/Switch/Switch";


interface SnowflakeSectionProps {
    integration: SnowflakeIntegration;
    onSave: (integration: SnowflakeIntegration) => void;
}
interface ErrorMessages {
    creditCost: string | null;
}
const SnowflakeSection: React.FC<SnowflakeSectionProps> = ({
                                                                                onSave,
                                                                                ...props
                                                                            }) => {
    const { integration, handleChangeProperty, handleEnabledToggle }
        = useIntegrationSectionState(props.integration);
    const { enabled, creditCost } = integration as SnowflakeIntegration;
    const [errors, setErrors] = useState<ErrorMessages>({
        creditCost: null,
    });
    const handleSave = () => {
        let hasError = false;
        const newErrors: ErrorMessages = { creditCost: null };

        if (enabled) {
            if (!creditCost) {
                newErrors.creditCost = "Credit Cost cannot be empty.";
                hasError = true;
            }

            setErrors(newErrors);
        }
        if (!hasError) {
            newErrors.creditCost = "";
            setErrors(newErrors);
            onSave(integration as SnowflakeIntegration);
        }
    };
    return (
        <>
            <Grid
                container
                spacing={2}
                direction="column"
                justifyContent="flex-start"
                style={{ marginTop: "8px", marginBottom: "8px" }}
            >
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="enable">Enabled</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <Switch checked={enabled || false}
                                onChange={handleEnabledToggle}
                                name="enabled"
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="creditCost">Credit Cost ($US)</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            disabled={!enabled}
                            name="creditCost"
                            fullWidth
                            textAlign="left"
                            value={creditCost}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                          {enabled && errors.creditCost && <div style={{ color: 'red', marginTop: '4px', textAlign: 'left' }}>{errors.creditCost}</div>}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={6}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center">
                    <Grid item xs={1}>
                        {/*@ts-ignore*/}
                        <SubmitButton onClick={handleSave}>Save</SubmitButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default SnowflakeSection;

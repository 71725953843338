import React from "react";
// import {useSelector} from "react-redux";
import Dimensions from 'react-dimensions';
import { useDispatch, useSelector } from "react-redux";
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import TableContainer from "@material-ui/core/TableContainer";
import {Scrollbars} from 'react-custom-scrollbars';
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { openSpikePreview } from "reducers/dashboard/dashlets/heatmap/heatmapSpikePreviewSlice";
import { formatFloatNumber } from "dashlets/HeatmapDashlet/oldUtils";

/**
 * Heatmap Alert History Dashlet View component.
 * @param props {dashlet, data, containerHeight, containerWidth}
 * @returns {JSX.Element}
 * @constructor
 */
const HeatmapAlertHistoryDashletView = ({dashlet, containerHeight, containerWidth}) => {
    const dispatch = useDispatch();

    const openPreview = (targetName, timestamp) => {
        dispatch(openSpikePreview(targetName, timestamp));
    }

    const alerts = useSelector(state => {
        return state.dashboard.dashletsData[dashlet.config.connectedHeatmapDashletId]?.alerts
    });

    const headerStyle = {
        fontWeight: 'bold',
    };
    const root = {
        overflowX: 'initial',
    }
    const table = {
        minWidth: 200,
        overflowX: 'initial',
        width:'100%',
    }
    const formatDateTime = (timestamp) => {
        return moment(timestamp).format('M/D/YYYY hh:mm:ss');
    };

    return <React.Fragment>
        <Scrollbars className="historyTable" style={{height: '102%'}}>
        <TableContainer style={{width:'auto',overflow:'unset',marginTop: '20px'}} className={root}>        
        <Table className={table} size="small" aria-label="a dense table">
            <TableHead>
                <TableRow>
                    <TableCell  style={headerStyle}>Target</TableCell>
                    <TableCell  style={headerStyle}>Template</TableCell>
                    <TableCell  style={headerStyle}>Metric / Measurement</TableCell>
                    <TableCell  style={headerStyle}>Data</TableCell>
                    <TableCell  style={headerStyle}>Alert Rank</TableCell>
                    <TableCell  style={headerStyle}>Timestamp</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {alerts?.map((al, index) => (
                    al.alerts.map((row, rowIndex) => (
                        row !== undefined && <TableRow key={`${index}-${rowIndex}`} onClick={() => {
                            openPreview(row.target, row.timestamp);
                        }} style={{ cursor: 'pointer' }}>
                            <TableCell>{row.target}</TableCell>
                            <TableCell>{row.templateName}</TableCell>
                            <TableCell>{row.metric} / {row.measurement}</TableCell>
                            <TableCell>{row.dataValueLong}</TableCell>
                            <TableCell>{formatFloatNumber(row.dataValueDouble)}</TableCell>
                            <TableCell>{formatDateTime(row.timestamp)}</TableCell>
                        </TableRow>
                    ))
                ))}
            </TableBody>
        </Table>
        </TableContainer>
        </Scrollbars>
    </React.Fragment>
};

export default Dimensions({elementResize: true})(HeatmapAlertHistoryDashletView);

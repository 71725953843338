import React, { Component } from 'react';
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, CardContent, CardHeader, Checkbox, DialogActions, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, SvgIcon, TextField } from "@material-ui/core";
import { APP_CONF, HLC, PEI } from '../../../constants/appsConfig';
import StompClient from "../../../websocket/StompClient";
import { STOMP_CLIENT } from "../../../reducers/webSocketSlice";
import { APP_LEGAL_URL, DASHBOARD_URL, HLC_SERVER_URL, HLC_WEB_SOCKET_URL, IS_HLC_WS_ENABLED, IS_PEI_WS_ENABLED, PEI_WEB_SOCKET_URL } from "config";
import PEIChartBagWebSocketSubscription from 'dashlets/PEIChartBagDashlet/components/PEIChartBagWebSocketSubscription';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import LaunchIcon from '@material-ui/icons/Launch';
import HLCDashletsRestClient from 'api/rest/HLCDashletsRestClient';
import * as _ from "lodash";
import TargetDatabaseType from 'types/model/common/TargetDatabaseType';
import { Scrollbars } from 'react-custom-scrollbars';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SplitPane from 'react-split-pane';
import HLCUserSessionWebSocketSubscription from 'websocket/HLCUserSessionWebSocketSubscription';
import { TEST_BACKEND_SUBSCRIPTION } from 'dashlets/HLCChartDashlet/webSocketClient';
// import { verify } from 'crypto';
const styles = theme => ({
  root: {
    display: 'grid',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    fontFamily: 'Amazon Ember Regular, "Roboto", "Helvetica", "Arial", sans-serif',
    textAlign: 'left',
  },
  heading: {
    color: '#ec7211',
    fontSize: '25px',
    marginTop: '18px',
    marginBottom: 'auto',
    fontSize: '18px',
  },
  topSection: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
  },
  // cardWrapper: {
  //   margin: theme.spacing(1),
  // },
  card: {
    width: '750px',
    boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)',
    height: '55vh', 
    maxHeight: '35rem',
  },
  cardContent: {
    padding: '20px 35px 20px 35px',
  },
  defaultView: {
    marginTop: '25px',
  },
  buttonsWrapper: {
    width: '30rem',
    justifySelf: 'center',
  },
  actionButtonLabel: {
    fontWeight: 600,
  },
  listItem: {
    marginBottom: '7px',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
    opacity: .2,
  },
  tooltip: {
    fontSize: '1rem', // Consider making this larger if the tooltip is too hard to read.
    maxWidth: 'none', // Set a max-width to control the width of the tooltip.
    lineHeight: '1.4', // Improve readability with increased line height.
    padding: theme.spacing(1), // Add more padding around the text.
    backgroundColor: '#f5f5f5', // Change the background color if needed for better contrast.
    color: 'rgba(0, 0, 0, 0.87)', // Ensure text color is readable against the background.
    //borderRadius: theme.shape.borderRadius, // Use theme's border radius for consistency.
    borderRadius: '12px', // Adjust this value to increase or decrease the roundness of the corners
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)', // Add a shadow for depth.
    border: '2px solid #dadce0', // Optional: add a border for more definition.
  },
  formControlLabelWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '15px',
  },
  submitButton: {
    marginLeft: 'auto',
    // marginTop:'100px',
  },
  backendStatusText: {
    color: 'red',
    marginRight:'auto',
  },
  cardHeaderTitle: {
    marginTop:'10px',
  },
  cardHeader: {
    paddingTop: '10px',
    paddingBottom: 'unset',
    flexDirection: 'row-reverse',
    alignItems: 'stretch',
  },
  backButton: {
    padding: '3px',
    marginTop:'18px',
  },
  cardHeaderContent: {
    marginLeft: '20px',
  },
  guidelineButtons:{
    backgroundColor: '#ec7211',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '10px',
    transition: 'background-color 0.3s, transform 0.2s',
    textTransform: 'none',
    height:'35px',
  },
  link: {
    color: '#5797c7', 
    textDecoration: 'none', 
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

const LargeButton = withStyles(theme => ({
  root: {
    width: '100%',
    height: '5rem',
    background: '#fff',
    padding: 0,
    marginBottom: theme.spacing(1),
    transition: 'all 0.5s ease',

    '&:hover': {
      background: '#fff'
    }
  },
  label: {
    height: '100%',
    width: '100%',
    textTransform: 'none',
  }
}))(Button);

class StartPageContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBackend: '',
      step: 'backends',
      backends: [],
      connectivityMessage: '',
      createDashboard: true,
      title: 'Backends',
      isVerifying: false,
      isVerified: false,
      isPEIVerifying: false,
      isPEIVerified: false,
      connectivityPEIMessage: '',
    };

    HLCDashletsRestClient.listUserBackends()
      .then(backends => {
        this.setState({ backends: backends })
      })
      .catch(error => {
        console.error(error);
      });
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.backendKey != this.props.backendKey) {
      this.setState({isVerifying: false, isVerified: false, connectivityMessage: ''});
    } 
  }

  handleChangeInput = (event) => {
    this.props.handleChangeProperty(event.target.name, event.target.value);
  };

  toggle = (event) => {
    const name = event.target.name;
    this.props.handleChangeProperty(name, !this.props.targetConfiguration[name]);
  };
  handleBackendChange = (event) => {
    this.setState({ selectedBackend: event.target.value });
    if (event.target.value === 'standalone') {
      this.setState({ step: 'guidelines' });
    } else {
      this.setState({ step: 'nextStep' });
    }
  };

  handleClickTestPEIBackendConnectivity = () => {
    this.setState({ isPEIVerifying: true });
    document.body.style.cursor = 'wait';

    if (this.props.isPEIStompClientConnected) {
      this.setState({ isPEIVerified: true });
    } else {
      this.setState({ connectivityPEIMessage: "PEI Backend not connected" });
    }

    this.setState({ isPEIVerifying: false });
    document.body.style.cursor = 'default';
  };

  toggleCreateDashboard = () => {
    this.setState({ createDashboard: !this.state.createDashboard })
  }

  // handleClickTestBackendConnectivity = () => {
  //   if (this.props.isHLCStompClientConnected) {
  //     this.setState({ conactivityMessage: "Backend was connected" });
  //   } else {
  //     this.setState({ conactivityMessage: "Backend was not connected" });
  //   }
  // }


  handleNextStep = () => {
    this.setState({ step: 'nextStep' });
  };

  handleBackClick = (stepName) => {
    // Implement your back navigation logic here
    // For example, if you want to go back to a previous step or view
    if (stepName === 'backends') {
      this.setState({ selectedBackend: '' })
    }
    this.setState({ step: stepName }); // Example: go back to guidelines step
    if (this.props.startPageApplication == PEI) {
      this.handleClickTestPEIBackendConnectivity();
    } else if (this.props.startPageApplication == HLC) {
      // this.handleClickTestBackendConnectivity();
    }
  };

  handleSubmit = () => {
    this.props.createTarget(this.state.createDashboard);
    // if (this.state.createDashboard) {
      // window.open(DASHBOARD_URL, '_blank');
    // } else {
    this.setState({ step: 'finalStep' });
    // }
  }


  handleClickBackendConnectivity = () => {
    this.setState({ isVerifying: true });
    this.setState({connectivityMessage: ''});
    // Start a timeout to set isVerifying to false after 5 seconds
    this.timeoutId = setTimeout(() => {
      this.setState({ isVerifying: false });
      this.setState({ connectivityMessage: "Backend not connected" });
    }, 5000);  // 5 seconds timeout
    
    // Call the function to check backend connectivity
    this.props.handleCheckBackendConnection();
  };

  handleTestBackendResponse = (response) => {
    // Clear the timeout if the response is received within 10 seconds
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    
    // Update your state based on the response
    this.setState({ 
      isVerifying: false,
      isVerified: true,
      connectivityMessage: ''
    });
  };
  

  render() {
    const { classes, startPageApplication, downloadDefaultBackend, errors, handleChangeInput, backendKey, isHLCStompClientConnected } = this.props;
    const {
      targetConfiguration: {
        databaseType,
        databaseHost,
        databasePort,
        databaseName,
        databaseUser,
        databasePassword,
        databaseUrl,
        useDatabaseUrl,
        hidePassword,
      },
    } = this.props;
    const disable = this.state.selectedBackend !== 'cloud' && !backendKey;
    const renderButtons = () => {
      if (startPageApplication === HLC) {
        return (
          <div className={classes.buttonsWrapper} style={{ height: "50%" }}>
            <Tooltip
              title="Before launching UpBeat first time, please download the 'UpBeat Database Backend' using the button below."
              classes={{ tooltip: classes.tooltip }}>
              <LargeButton
                variant="contained"
                component="a"
                href={APP_CONF[startPageApplication].URL}
                target="_blank"
              >
                <SvgIcon className={classes.leftIcon}>
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sign-in-alt"
                    className="svg-inline--fa fa-sign-in-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512">
                    <path fill="currentColor"
                      d="M416 448h-84c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h84c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32h-84c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h84c53 0 96 43 96 96v192c0 53-43 96-96 96zm-47-201L201 79c-15-15-41-4.5-41 17v96H24c-13.3 0-24 10.7-24 24v96c0 13.3 10.7 24 24 24h136v96c0 21.5 26 32 41 17l168-168c9.3-9.4 9.3-24.6 0-34z"></path>
                  </svg>
                </SvgIcon>
                {APP_CONF[startPageApplication].START_APP_TITLE}
              </LargeButton>
            </Tooltip>

            <Tooltip
              logging title="Once downloaded, unzip the database backend, and execute 'start.bat' for Windows or 'start.sh' for Linux to connect to the UI."
              classes={{ tooltip: classes.tooltip }}>
              <LargeButton
                variant="contained"
                component="a"
                onClick={downloadDefaultBackend}
              >
                <SvgIcon className={classes.leftIcon}>
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="download"
                    className="svg-inline--fa fa-download fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512">
                    <path fill="currentColor"
                      d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path>
                  </svg>
                </SvgIcon>
                Download Database Backend
              </LargeButton>
            </Tooltip>

          </div>
        );
      }

      return (
        <div className={classes.buttonsWrapper}>
          <Tooltip
            title="Before launching UpBeat first time, please download the 'UpBeat Database Backend' using the button below."
            classes={{ tooltip: classes.tooltip }}>
            <LargeButton
              variant="contained"
              component="a"
              href={APP_CONF[startPageApplication].URL}
              target="_blank"
            >
              <SvgIcon className={classes.leftIcon}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sign-in-alt"
                  className="svg-inline--fa fa-sign-in-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512">
                  <path fill="currentColor"
                    d="M416 448h-84c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h84c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32h-84c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h84c53 0 96 43 96 96v192c0 53-43 96-96 96zm-47-201L201 79c-15-15-41-4.5-41 17v96H24c-13.3 0-24 10.7-24 24v96c0 13.3 10.7 24 24 24h136v96c0 21.5 26 32 41 17l168-168c9.3-9.4 9.3-24.6 0-34z"></path>
                </svg>
              </SvgIcon>
              {APP_CONF[startPageApplication].START_APP_TITLE}
            </LargeButton>
          </Tooltip>

          <Tooltip
            title="Once downloaded, unzip the database backend, and execute 'start.bat' to connect to the UI."
            classes={{ tooltip: classes.tooltip }}>
            <LargeButton
              variant="contained"
              component="a"
              href={`${APP_CONF[startPageApplication].API.DOWNLOAD_AGENT}?platform=win64`}
              target="_blank"
            >
              <SvgIcon className={classes.leftIcon}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="download"
                  className="svg-inline--fa fa-download fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512">
                  <path fill="currentColor"
                    d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path>
                </svg>
              </SvgIcon>
              Download Database Backend (Windows x64)
            </LargeButton>
          </Tooltip>

          <Tooltip
            title="Once downloaded, unzip the database backend, then run 'start.sh' to connect to the UI."
            classes={{ tooltip: classes.tooltip }}>
            <LargeButton
              variant="contained"
              component="a"
              href={`${APP_CONF[startPageApplication].API.DOWNLOAD_AGENT}?platform=linux64`}
              target="_blank"
            >
              <SvgIcon className={classes.leftIcon}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="download"
                  className="svg-inline--fa fa-download fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512">
                  <path fill="currentColor"
                    d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path>
                </svg>
              </SvgIcon>
              Download Database Backend (Linux x64)
            </LargeButton>
          </Tooltip>
        </div>
      );
    }

    const renderGuidelineContent = () => {
      return (
        <>
          {startPageApplication === HLC && (
            <>
              <h4 className={classes.heading} style={{ fontSize: '18px', padding: '0' }}>Guidelines:</h4>
    
              <h3 style={{ fontSize: '16px' }}>1. Start Upbeat High Load Capture</h3>
              <ul style={{ textAlign: 'justify', fontSize: '15px', marginTop: '-10px' }}>
                <li className={classes.listItem}>Start Dashboard</li>
                <li className={classes.listItem}>Before launching UpBeat for the first time, please download the 'UpBeat Database Backend' using the button below.</li>
                <li className={classes.listItem}>Once the download is complete, you can visit the high load capture using the visit button.</li>
              </ul>
              <Button
                variant="contained"
                component="a"
                href={APP_CONF[startPageApplication]?.URL} // Optional chaining for safety
                target="_blank"
                startIcon={<LaunchIcon />}
                className={classes.guidelineButtons}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e25e00'}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#ec7211'}
              >
                Launch
              </Button>
    
              <h3 style={{ fontSize: '16px' }}>2. Default Database Backend</h3>
              <ul style={{ textAlign: 'justify', fontSize: '15px', marginTop: '-10px' }}>
                <li className={classes.listItem}>Start Dashboard</li>
                <li className={classes.listItem}>You can download the default backend using the button below.</li>
                <li className={classes.listItem}>Once the download is complete, unzip the file named <code>hlc-db-backend</code> which will be extracted as <code>hlc-db-backend-shared</code>.</li>
                <li className={classes.listItem}>Open the <code>hlc-db-backend-shared</code> folder and select your path, e.g., <code>D:\Apps\hlc-db-backend\hlc-db-backend-shared</code>, and then type <code>"cmd"</code> in the address bar and press Enter.</li>
                <li className={classes.listItem}>Open a new Windows CMD or a Linux shell terminal and type the command <code>"startup.bat"</code> for Windows or <code>"startup.sh"</code> for Linux.</li>
                <li className={classes.listItem}>Start the UpBeat application by clicking the "Launch" button above.</li>
              </ul>
              <Button
                variant="contained"
                onClick={downloadDefaultBackend}
                startIcon={<GetAppIcon />}
                className={classes.guidelineButtons}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e25e00'}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#ec7211'}
              >
                Download
              </Button>
            </>
          )}

          {startPageApplication === PEI && (
            <>
              <h4 className={classes.heading} style={{ fontSize: '18px', padding: '0' }}>Guidelines:</h4>
    
              <h3 style={{ fontSize: '16px' }}>1. Start Performance Explorer Backend</h3>
              <ul style={{ textAlign: 'justify', fontSize: '15px', marginTop: '-10px' }}>
                <li className={classes.listItem}>Start Dashboard</li>
                <li className={classes.listItem}>Before launching UpBeat for the first time, please download the 'UpBeat Database Backend' using the button below.</li>
                <li className={classes.listItem}>Once the download is complete, you can visit the performance Explorer using the visit button.</li>
              </ul>
              <Button
                variant="contained"
                component="a"
                href={APP_CONF[startPageApplication]?.URL} 
                target="_blank"
                startIcon={<LaunchIcon />}
                className={classes.guidelineButtons}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e25e00'}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#ec7211'}
              >
                Launch
              </Button>
    
              <h3 style={{ fontSize: '16px' }}>2. Default Database Backend</h3>
              <ul style={{ textAlign: 'justify', fontSize: '15px', marginTop: '-10px' }}>
                <li className={classes.listItem}>Start Dashboard</li>
                <li className={classes.listItem}>Download platform specific backend as per your Operating System(OS).</li>
                <li className={classes.listItem}>Once the download is complete, unzip the file named <code>pei-db-backend</code> which will be extracted as <code>pei-db-backend-shared</code>.</li>
                <li className={classes.listItem}>Open the <code>pei-db-backend-shared</code> folder and select your path, e.g., <code>D:\Apps\pei-db-backend\pei-db-backend-shared</code>, and then type <code>"cmd"</code> in the address bar and press Enter.</li>
                <li className={classes.listItem}>Open a new Windows CMD or a Linux shell terminal and type the command <code>"startup.bat"</code> for Windows or <code>"startup.sh"</code> for Linux.</li>
                <li className={classes.listItem}>Start the UpBeat pei application by clicking the "Launch" button above.</li>
              </ul>
              <Button
                variant="contained"
                href={`${APP_CONF[startPageApplication].API.DOWNLOAD_AGENT}?platform=win64`}
                target="_blank"
                startIcon={<GetAppIcon />}
                className={classes.guidelineButtons}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e25e00'}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#ec7211'}
              >
                Download (Windows x64)
              </Button>
              <Button
                variant="contained"
                href={`${APP_CONF[startPageApplication].API.DOWNLOAD_AGENT}?platform=linux64`}
                target="_blank"
                startIcon={<GetAppIcon />}
                style={{marginLeft: '4px'}}
                className={classes.guidelineButtons}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e25e00'}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#ec7211'}
              >
                Download (Linux x64)
              </Button>
            </>
          )}
        </>
      );
    };
    
    return (
      <div className={classes.root} id='startPage'>
        <HLCUserSessionWebSocketSubscription
            key={`hlc-set-backend-subscription`}
            subscriptionDestination={TEST_BACKEND_SUBSCRIPTION}
            handleMessage={response => this.handleTestBackendResponse(response)}
        />
        {startPageApplication !== PEI && (
        <SplitPane
            style={{ position: "relative", height: "100%"}}
            primary="first"
            split="horizontal"
            minSize={100}
            maxSize={-100}
            defaultSize={"75%"}
          >
            
            {startPageApplication === HLC && (<div className={classes.topSection}>
              <div className={classes.cardWrapper}>
                <Card className={classes.card}>
                  <CardHeader
                    className="GridDragHandler"
                    classes={{
                      root: classes.cardHeader,
                      action: classes.cardHeaderAction,
                      content: classes.cardHeaderContent,
                      title: classes.cardHeaderTitle,
                    }}
                    action={
                      this.state.step != 'backends' && (
                        <IconButton
                          onClick={() => {
                            let stepName = "";
                            if (this.state.step === 'guidelines' || (this.state.step === 'nextStep' && this.state.selectedBackend != 'standalone'))
                              stepName = "backends"
                            else
                              stepName = "guidelines"
                            this.handleBackClick(stepName);
                          }} className={classes.backButton}>
                          <ArrowBackIcon />
                        </IconButton>)
                    }
                    title={this.state.step === 'backends' ? this.state.title : this.state.step === 'guidelines' ? 'Guidelines' : this.state.step === 'nextStep' ? 'Create Dashboard' : undefined}
                  />
                  <Scrollbars style={{ height: 'calc(100% - 46px)' }}>
                    <CardContent className={classes.cardContent}>
                      {this.state.step !== 'finalStep' && (
                      <Grid item xs={12}>
                        <FormControl variant="outlined" size="small" margin="dense" fullWidth>
                          <InputLabel id="select-backend-label">Select Backend</InputLabel>
                          <Select
                            labelId="select-backend-label"
                            id="selectedBackend"
                            name="selectedBackend"
                            value={this.state.selectedBackend}
                            onChange={this.handleBackendChange}
                            label="Select Backend"
                            fullWidth
                          >
                            <MenuItem value="standalone"> Standalone Backend </MenuItem>
                            <MenuItem value="cloud"> Cloud Backend </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      )}
                      {this.state.step === 'backends' && (
                        <div className={classes.defaultView}>
                          <h3 className={classes.heading}> 1. Standalone Backend</h3>
                          <ul style={{ textAlign: 'justify', fontSize: '15px' }}>
                            <li>
                              The Standalone backend allows users to visit a high-load capture URL and download the backend database. This backend enables users to create their own default target, ensuring they can set up and manage fallback configurations effectively.
                            </li> <br />
                            <li>
                              By providing this functionality, users can handle high-traffic situations, ensure reliable request processing, and customize their fallback responses according to their specific needs.
                            </li>
                          </ul>

                          <h3 className={classes.heading}> 2. Cloud Backend</h3>
                          <ul style={{ textAlign: 'justify', fontSize: '15px' }}>
                            <li>
                              A cloud backend is a server-side infrastructure hosted on cloud platforms that provides the necessary resources and services to power applications.
                            </li> <br />
                            <li>
                              It offers scalability, flexibility, and reliability by utilizing cloud-based services for data storage, processing, and management. Users can leverage cloud backends to handle high volumes of traffic, integrate various APIs, and manage databases efficiently.
                            </li>
                          </ul>
                        </div>
                      )}

                      {this.state.selectedBackend === "standalone" && this.state.step === 'guidelines' && (
                        <div>  
                          <Grid item xs={12}>
                              <FormControl margin="dense" variant="outlined" size="small" fullWidth>
                                <InputLabel id="hlc-backend">Select Upbeat Backend</InputLabel>
                                <Select
                                  labelId="dashlet-hlc-chart-backendKey-label-1582673886219"
                                  id="backendKey"
                                  name="backendKey"
                                  value={backendKey ? backendKey : ''}
                                  onChange={this.props.handleBackendSelect}
                                  label="Select Upbeat Backend"
                                  fullWidth
                                >
                                  <MenuItem key="dashlet-hlc-chart-backendKey-menu-item-1582673956819" value=""></MenuItem>
                                  {_.map(this.state.backends, (backend) => {
                                    return (
                                      <MenuItem
                                        key={`dashlet-hlc-chart-backendKey-menu-item-key-${backend.backendKey}`}
                                        value={backend.backendKey}>
                                        {backend.name}
                                      </MenuItem>
                                    )
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                          <div style={{marginTop:'20px'}}>
                            <DialogActions>
                              <div className={classes.backendStatusText}>{this.state.connectivityMessage}</div>
                              <Button
                                color="secondary"
                                classes={{ label: classes.actionButtonLabel }}
                                onClick={this.handleClickBackendConnectivity}
                                disabled={this.state.isVerifying || this.state.isVerified || !backendKey}
                                style={{ color: this.state.isVerified ? 'green' : this.state.isVerifying ? '#5797c7' : ''}}
                                >
                                {this.state.isVerifying ? 'Verifying...' : this.state.isVerified ? 'Verified' : 'Verify Backend Connectivity'}
                              </Button>
                              <Button color="primary" onClick={this.handleNextStep} disabled={ !this.state.isVerified } classes={{
                                label: classes.actionButtonLabel
                              }}>
                                Create Dashboard
                              </Button>
                            </DialogActions>
                          </div>                                    
                          {renderGuidelineContent()}
                          <DialogActions >
                            <div className={classes.backendStatusText}>{this.state.connectivityMessage}</div>
                            <Button
                              color="secondary"
                              classes={{ label: classes.actionButtonLabel }}
                              onClick={this.handleClickBackendConnectivity}
                              disabled={this.state.isVerifying || this.state.isVerified || !backendKey}
                              style={{ color: this.state.isVerified ? 'green' : this.state.isVerifying ? '#5797c7' : ''}}
                            >
                              {this.state.isVerifying ? 'Verifying...' : this.state.isVerified ? 'Verified' : 'Verify Backend Connectivity'}
                            </Button>
                            <Button color="primary" onClick={this.handleNextStep} disabled={ !this.state.isVerified } classes={{
                              label: classes.actionButtonLabel
                            }}>
                              Create Dashboard
                            </Button>
                          </DialogActions>
                        </div>
                      )}
                      {(this.state.selectedBackend === "cloud" || this.state.step === 'nextStep') && (
                        <div>
                          {this.state.selectedBackend !== "cloud" && (
                            <Grid item xs={12}>
                              <FormControl margin="dense" variant="outlined" size="small" fullWidth>
                                <InputLabel id="hlc-backend">Select Upbeat Backend</InputLabel>
                                <Select
                                  labelId="dashlet-hlc-chart-backendKey-label-1582673886219"
                                  id="backendKey"
                                  name="backendKey"
                                  value={backendKey ? backendKey : ''}
                                  onChange={this.props.handleBackendSelect}
                                  label="Select Upbeat Backend"
                                  fullWidth
                                >
                                  <MenuItem key="dashlet-hlc-chart-backendKey-menu-item-1582673956819" value=""></MenuItem>
                                  {_.map(this.state.backends, (backend) => {
                                    return (
                                      <MenuItem
                                        key={`dashlet-hlc-chart-backendKey-menu-item-key-${backend.backendKey}`}
                                        value={backend.backendKey}>
                                        {backend.name}
                                      </MenuItem>
                                    )
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <FormControl margin="dense" variant="outlined" size="small" fullWidth>
                              <InputLabel id="database-type-label">Database Type</InputLabel>
                              <Select
                                id="databaseType"
                                name="databaseType"
                                labelId="database-type-label"
                                value={databaseType}
                                onChange={this.handleChangeInput}
                                disabled={disable}
                                label="Database Type"
                                fullWidth
                              >
                                {Object.values(TargetDatabaseType).map((dbType) => (
                                  // @ts-ignore
                                  <MenuItem key={`db-type-${dbType}`} value={dbType}>
                                    {dbType}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item container spacing={1}>
                            <Grid item xs={8}>
                              <TextField
                                required
                                error={errors.databaseHost}
                                id="databaseHost"
                                name="databaseHost"
                                label="Host"
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                size="small"
                                value={databaseHost}
                                onChange={handleChangeInput}
                                disabled={disable}
                              />
                            </Grid>

                            <Grid item xs={4}>
                              <TextField
                                required
                                error={errors.databasePort}
                                id="databasePort"
                                name="databasePort"
                                label="Port"
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                size="small"
                                value={databasePort}
                                onChange={handleChangeInput}
                                disabled={disable}
                              />
                            </Grid>
                          </Grid>

                          <Grid item container spacing={1}>
                            <Grid item xs={6}>
                              <TextField
                                required
                                error={errors.databaseUser}
                                id="databaseUser"
                                name="databaseUser"
                                label="Username"
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                size="small"
                                value={databaseUser}
                                onChange={handleChangeInput}
                                disabled={disable}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                required
                                error={errors.databasePassword}
                                id="databasePassword"
                                name="databasePassword"
                                label="Password"
                                type="password"
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                size="small"
                                value={databasePassword}
                                onChange={handleChangeInput}
                                disabled={disable}
                              />
                            </Grid>
                          </Grid>

                          <Grid item>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id="hidePassword"
                                  name="hidePassword"
                                  size="small"
                                  color='primary'
                                  checked={hidePassword}
                                  onChange={this.toggle}
                                />
                              }
                              label="Hide Password"
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              required
                              error={errors.databaseName}
                              id="databaseName"
                              name="databaseName"
                              label="Database"
                              variant="outlined"
                              fullWidth
                              margin="dense"
                              size="small"
                              value={databaseName}
                              onChange={handleChangeInput}
                              disabled={disable}
                            />
                          </Grid>

                          <Grid item>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color='primary'
                                  id="useDatabaseUrl"
                                  name="useDatabaseUrl"
                                  size="small"
                                  checked={useDatabaseUrl}
                                  onChange={this.toggle}
                                  disabled={disable}
                                />
                              }
                              label="Use Database/JDBC URL"
                            />
                          </Grid>

                          <Grid item>
                            <TextField
                              required={useDatabaseUrl}
                              error={errors.databaseUrl}
                              multiline
                              maxRows={5}
                              id="databaseUrl"
                              name="databaseUrl"
                              label="Database/JDBC URL"
                              variant="outlined"
                              fullWidth
                              margin="dense"
                              size="small"
                              disabled={!useDatabaseUrl || (disable)}
                              value={databaseUrl}
                              onChange={handleChangeInput}
                            />
                          </Grid>

                          <div className={classes.formControlLabelWrapper}>
                            {this.state.selectedBackend === "cloud" && (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color='primary'
                                    id="useDatabaseUrl"
                                    name="useDatabaseUrl"
                                    size="small"
                                  />
                                }
                                label={
                                  <span>
                                    Accept <a href={APP_LEGAL_URL} target="_blank" className={classes.link}> policy </a>
                                  </span>
                                }
                              />
                            )}
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color='primary'
                                  id="useDatabaseUrl"
                                  name="useDatabaseUrl"
                                  size="small"
                                  checked={this.state.createDashboard}
                                  onChange={this.toggleCreateDashboard}
                                  disabled={disable}
                                />
                              }
                              label="Create Default Dashboard"
                            />
                            <Button
                              onClick={this.handleSubmit}
                              variant="contained"
                              color="primary"
                              className={classes.submitButton}
                              disabled={disable}
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      )}
                      {this.state.step === 'finalStep' && (
                        <div>
                          <h3 className={classes.heading}> 1. High Load Capture </h3>
                          <ul style={{ textAlign: 'justify', fontSize: '15px'}}>
                            <li className={classes.listItem}>
                              To proceed further, you can explore the High Load Capture (HLC) by using the button below. This allows you to analyze and capture high-load performance metrics. 
                            </li>
                            <li className={classes.listItem}>
                              Click the "Launch HLC" button to open the HLC in a new tab and start exploring its features.
                            </li>
                            <Button
                              variant="contained" component="a" href={APP_CONF[startPageApplication].URL} target="_blank" startIcon={<LaunchIcon />}
                              className={classes.guidelineButtons}
                              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e25e00'}
                              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#ec7211'}
                            >
                              Launch HLC
                            </Button> 
                          </ul>
                          <h3 className={classes.heading}> 2. Dashboard </h3>
                          <ul style={{ textAlign: 'justify', fontSize: '15px'}}>
                            <li className={classes.listItem}>
                              To proceed, you can access the dashboard by clicking the button below. This will allow you to view and interact with the dashboard to explore its features and data.
                            </li>
                            <Button
                              variant="contained" component="a" href={DASHBOARD_URL} target="_blank" startIcon={<LaunchIcon />}
                              className={classes.guidelineButtons}
                              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e25e00'}
                              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#ec7211'}
                            >
                              Launch Dashboard
                            </Button> 
                          </ul>
                        </div>
                      )}
                    </CardContent>
                  </Scrollbars>
                </Card>
              </div>
            </div>)}
          <Box flexGrow={1} style={{backgroundColor: "white", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", overflow: "auto"}}>
            {renderButtons()}
          </Box>
        </SplitPane>
        )}

        {startPageApplication === PEI && (
          <SplitPane
            style={{ position: "relative", height: "100%" }}
            primary="first"
            split="horizontal"
            minSize={100}
            maxSize={-100}
            defaultSize={"75%"}
          >
              <div className={classes.topSection}>
                <div className={classes.cardWrapper}>
                  <Card className={classes.card}>
                    <CardHeader
                      className="GridDragHandler"
                      classes={{
                        root: classes.cardHeader,
                        action: classes.cardHeaderAction,
                        content: classes.cardHeaderContent,
                        title: classes.cardHeaderTitle,
                      }}

                      title='Guidelines'
                    />
                    <Scrollbars style={{ height: 'calc(100% - 46px)' }}>
                      <CardContent className={classes.cardContent}>
                        <div>
                          {renderGuidelineContent()}  
                          <DialogActions >
                            <div className={classes.backendStatusText}>{this.state.connectivityPEIMessage}</div>
                            <Button
                              color="secondary"
                              classes={{ label: classes.actionButtonLabel }}
                              onClick={this.handleClickTestPEIBackendConnectivity}
                              disabled={this.state.isPEIVerifying || this.state.isPEIVerified}
                              style={{ color: this.state.isPEIVerified ? 'green' : '' }}
                            >
                              {this.state.isPEIVerifying ? 'Verifying...' : this.state.isPEIVerified ? 'Verified' : 'Verify Connectivity'}
                            </Button>
                          </DialogActions>
                        </div>
                      </CardContent>
                    </Scrollbars>
                  </Card>
                </div>
              </div>
            <Box
              flexGrow={1}
              style={{
                backgroundColor: "white",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "auto",
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '100%', overflowY: 'auto', padding: '10%', boxSizing: 'border-box' }}>
                {renderButtons()}
              </div>
            </Box>
          </SplitPane>
        )}
        
        {IS_PEI_WS_ENABLED ? [
          <StompClient
            key="hlc-ws-stomp-client-1582840873831"
            stompClientKey={STOMP_CLIENT.PEI}
            sockJsEndpoint={PEI_WEB_SOCKET_URL}
            debugMode
          />,
          <PEIChartBagWebSocketSubscription />
        ] : null}
        {IS_HLC_WS_ENABLED ? [
          <StompClient
            key="pei-ws-stomp-client-1582840873831"
            stompClientKey={STOMP_CLIENT.HLC}
            sockJsEndpoint={HLC_WEB_SOCKET_URL}
            debugMode
          />,
          // <EmailConfigurationView/>
        ] : null}
      </div>
    );
  }
}

StartPageContent.propTypes = {
  //data
  classes: PropTypes.object.isRequired,
  startPageApplication: PropTypes.string,
  objectData: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isLocked: PropTypes.bool,
  open: PropTypes.bool,
  targetConfiguration: PropTypes.object,
  isHLCStompClientConnected: PropTypes.bool,
  isPEIStompClientConnected: PropTypes.bool,

  //func
  downloadDefaultBackend: PropTypes.func,
  handleClose: PropTypes.func,
  handleChangeInput: PropTypes.func,
  handleChangeProperty: PropTypes.func,
  handleBackendSelect: PropTypes.func,
  createTarget: PropTypes.func,
  handleCheckBackendConnection: PropTypes.func,
};

export default withStyles(styles)(StartPageContent);

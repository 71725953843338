import React from "react";
import {Box} from "@material-ui/core";
import history from "routes/history";
import * as paths from "routes/paths";
import {Route, Router, Switch} from "react-router-dom";
import UpdatesList from "./UpdatesList";

const UpdatesContent: React.FC = () => (
    <Box style={{
        display: 'block',
        height: "100%",
        width: "100%",
        overflowY: "scroll",
        overflowX: "hidden"
    }}>
        <Router history={history}>
            <Switch>
                <Route
                    path={paths.BACKENDS_UPDATES}
                    render={() => <UpdatesList/>}
                />
            </Switch>
        </Router>
    </Box>
);

export default UpdatesContent;

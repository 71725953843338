import * as webSocketClient from './webSocketClient';

export const getEmptyTimeRangeSettings = () => {
    return {
        timeUnitsCount: 3,
        timeUnit: "month"
    }
}

export const refresh = (dashlet: any) => (dispatch: any, getState: any) => {
    const {backendKey, targetName} = dashlet.config;
    const {id, timeRange} = dashlet;

    console.log("timeRange ===>");
    console.log(dashlet);

    webSocketClient.generateSnowflakeDatabaseReport(getState(), backendKey, {
        dashletId: id,
        targetName: targetName,
        reportParams: timeRange,
    } );
};

export const validateConfig = (dashletConfig: any) => {
    const errors = {};

    console.debug('===> SnowflakeReportDashlet validateConfig() ');

    return errors;
};

export const buildDashletObject = (dashletData: any, configOptions: any) => {
    const dashletTimeRange = dashletData?.timeRange && Object.keys(dashletData.timeRange).length > 0
        ? dashletData.timeRange
        : getEmptyTimeRangeSettings();

    return {
        ...dashletData,
        timeRange: dashletTimeRange,
        backendKey: dashletData.config.backendKey
    }
};

import * as _ from "lodash";
import { generateDashlet } from 'actions/dashboardActions';
import * as webSocketClient from './webSocketClient';
import {selectDashletData, setDashletData} from "reducers/dashboard/dashboardSlice";

export const DEFAULT_TIME_FRAME_LENGTH_IN_MILLIS = 60 * 60 * 1_000; // 1 hour

export const refresh = dashlet => (dispatch, getState) => {
    const {backendKey} = dashlet.config;
    webSocketClient.loadInitialData(getState(), backendKey, dashlet.id, DEFAULT_TIME_FRAME_LENGTH_IN_MILLIS);
}

export const loadTargetSpikePreviewChart = (dashlet, targetName, timestamp) => (dispatch, getState) => {
    const {backendKey} = dashlet.config;
    webSocketClient.heatmapLoadTargetSpikePreviewChart(getState(), backendKey, dashlet.id, targetName, timestamp);
}

export const listTargetSpikes = (dashlet, targetName, count) => (dispatch, getState) => {
    const {backendKey} = dashlet.config;
    webSocketClient.listTargetSpikesRequest(getState(), backendKey, dashlet.id, targetName, count);
}

export const listReportNames = backendKey => (dispatch, getState) =>
    webSocketClient.listReportNames(getState(), backendKey);

export const listTemplateNames = backendKey => (dispatch, getState) =>
    webSocketClient.listTemplateNames(getState(), backendKey);

export const listTargetNames = backendKey => (dispatch, getState) =>
    webSocketClient.listTargetNames(getState(), backendKey);

export const setAlerts = (selectedDashlet, response, isInitialLoad) => (dispatch, getState) => {
    const dashlet = getState().dashboard.dashboardTree.entities.dashlets[selectedDashlet.id];
    if (dashlet != undefined && dashlet) {
        const data = selectDashletData(getState(), dashlet.id);
        let alertsWithDifferentTimestamps = [];
        const dashletData = response.targetAlerts.map(targetAlert => {
            let currentAlerts = [];
            let availableAlertsTimestamp = 0;
            if (data) {
                currentAlerts = data?.alerts.filter((alert) => {
                    return targetAlert.targetName === alert.targetName
                });
                if (currentAlerts && currentAlerts.length > 0) {
                    availableAlertsTimestamp = _.first(currentAlerts[0].alerts) ? _.first(currentAlerts[0].alerts).timestamp : 0;
                }
            } 

            const { alerts} = targetAlert;


            alertsWithDifferentTimestamps = alerts.filter(alert =>
                alert.timestamp > availableAlertsTimestamp
            );
            if (alertsWithDifferentTimestamps.length > 0)
                dispatch(generateDashlet(dashlet, alertsWithDifferentTimestamps));

            if (currentAlerts && currentAlerts.length > 0) {
                if (isInitialLoad) {
                    alertsWithDifferentTimestamps = alerts;
                } else {
                    alertsWithDifferentTimestamps = alertsWithDifferentTimestamps.concat(currentAlerts[0].alerts);
                }
            }
            alertsWithDifferentTimestamps.sort((a, b) => b.timestamp - a.timestamp);
            if (dashlet.config.anomalyMagnitude) {
                alertsWithDifferentTimestamps = alertsWithDifferentTimestamps.filter(alertsData => alertsData.dataValueDouble > dashlet.config.anomalyMagnitude);
            }
            return {
                ...targetAlert,
                alerts: alertsWithDifferentTimestamps
            }
        });
        dispatch(setDashletData(dashlet.id, {alerts: dashletData}));
    }
}

export const validateConfig = (dashletConfig) => {
    const errors = {};

    _.each(['backendKey', 'spikeDetectionReportName', 'staticTemplateName', 'targetNames'], (propertyName) => {
      if (!dashletConfig[propertyName]) {
        errors[propertyName] = true;
      }
    });
  
    return errors;
};

export const buildDashletObject = (dashletData, configOptions) => {
    return {
        ...dashletData,
        backendKey: dashletData.config.backendKey
    }
}
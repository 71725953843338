import {
    includes as _includes,
    groupBy as _groupBy,
    slice as _slice,
    last as _last,
    max as _max,
    maxBy as _maxBy,
    min as _min,
    keys as _keys,
    map as _map,
    each as _each,
    chunk as _chunk,
    cloneDeep as _cloneDeep,
    forEach as _forEach,
} from "lodash";
import numeral from "numeral";
import moment from "moment";
import * as _ from 'lodash';
import {HLC_URL} from "config";

const groupChartSeriesList = (chartSeriesList, groupBy) => {
    //group by capture, target, measurement, metric
    if (_includes(['capture', 'target', 'measurement', 'metric'], groupBy)) {
        return _groupBy(chartSeriesList, (seriesBean) => {
            return seriesBean[groupBy];
        });
    }

    //group by metric and measurement
    if (groupBy === 'metric_and_measurement') {
        return _groupBy(chartSeriesList, (seriesBean) => {
            return `${seriesBean.metric}|${seriesBean.measurement}`;
        });
    }

    //split metrics
    if (groupBy === 'split_metrics') {
        return _groupBy(chartSeriesList, (seriesBean) => {
            return `${seriesBean.target}|${seriesBean.capture}|${seriesBean.metric}|${seriesBean.measurement}`;
        });
    }

    //no grouping
    return {'none': chartSeriesList}
};

const chunkArray = (sourceArray, chunkSize) => {
    let index = 0;
    let arrayLength = sourceArray.length;
    let chunkList = [];

    for (index = 0; index < arrayLength; index += chunkSize) {
        chunkList.push(_slice(sourceArray, index, index + chunkSize));
    }

    return chunkList;
};

const splitGroupsToPages = (groupedChartSeriesMap, chartsPerPage) => {
    let chartDataPages = [];

    _each(chunkArray((_keys(groupedChartSeriesMap)), chartsPerPage), (groupKeyList) => {
        let chartPage = _map(groupKeyList, (groupKey) => {
            return {[groupKey]: groupedChartSeriesMap[groupKey]};
        });
        chartDataPages.push(chartPage);
    });

    return chartDataPages;
};

/**
 * Build Options object for Highcharts.
 *
 * @param dashlet dashlet object
 * @param dashletData dashlet data object
 * @param settings dashlet settings object
 * @param height height of the dashlet
 * @param width width of the dashlet
 * @param handleClickOnTarget function that handles click on the item on the Heatmap
 * @returns {{plotOptions: {series: {dataLabels: {color: string, format: string, style: {textOutline: boolean}, enabled: boolean}, point: {events: {dblclick: plotOptions.series.point.events.dblclick, contextmenu: plotOptions.series.point.events.contextmenu, click: plotOptions.series.point.events.click}}}}, yAxis: {visible: boolean}, xAxis: {visible: boolean}, accessibility: {point: {valueDescriptionFormat: string}}, legend: {enabled: boolean}, series: [{borderColor: string, data: [], borderWidth: number, name: string}], tooltip: {formatter: ((function(): (string|string))|*), headerFormat: string}, title: {text: string}, chart: {width, type: string, inverted: boolean, height}, colorAxis: {dataClasses: [{color: string, name: string, from: number, to: number},{color: string, name: string, from: number, to: number},{color: string, name: string, from: number, to: number},{color: string, name: string, from: number, to: number},{color: string, name: string, from: number}]}}}
 */
export const buildHeatmapChartConfig = (dashlet, dashletData, settings, height, width, handleClickOnTarget) => {
    const seriesData = [];

    const filteredDashletData = filterDashletData(dashletData, settings);
    if (filteredDashletData.length > 0) {
        const width = Math.round(Math.sqrt(filteredDashletData.length) + 1);
        const dashletDataChunks = _chunk(filteredDashletData, width);
        _forEach(dashletDataChunks, (chunk, y) => {
            _forEach(chunk, (targetAlertsData, x) => {
                const {targetName, alerts, targetRunning} = targetAlertsData;

                const heatmapItem = {
                    name: targetName,
                    targetName: targetName,
                    x: x,
                    y: y,
                    value: null,
                    timestamp: null,
                    lastAlert: null,
                    prevMaxAlert: null,
                    border: {}
                };

                // if target is not running
                if (!targetRunning) {
                    seriesData.push({
                        ...heatmapItem,
                        value: -1
                    });
                    return;
                }

                // if no alerts
                if (alerts.length < 1) {
                    seriesData.push({
                        ...heatmapItem,
                        value: 0
                    });
                    return;
                }

                // if any alert exists
                const lastAlert = _last(alerts);
                const lastAlertValueDouble = lastAlert?.dataValueDouble;
                const prevMaxAlert = getPreviousMaxAlert(alerts);

                const border = prevMaxAlert ? {
                    borderWidth: 2,
                    borderColor: 'red'
                } : {};

                seriesData.push({
                    ...heatmapItem,
                    value: lastAlertValueDouble ? Number(lastAlertValueDouble).toFixed(2) : 0,
                    timestamp: lastAlert?.timestamp,
                    lastAlert: lastAlert,
                    prevMaxAlert: prevMaxAlert,
                    ...border
                });
            });
        });
    }

    return {
        chart: {
            type: 'heatmap',
            inverted: true,
            height: height,
            width: width,
        },
        credits: {
            enabled: false
        },
        title:{
            text:''
        },
        legend: {
            enabled: false
        },
        accessibility: {
            point: {
                valueDescriptionFormat: '{index}. {xDescription}, {point.value}.'
            }
        },
        xAxis: {
            visible: false
        },
        yAxis: {
            visible: false
        },
        colorAxis: {
            dataClasses: [{
                from: -2,
                to: -0.5,
                color: '#808080',
                name: '< 0M'
            }, {
                from: -0.9,
                to: 1.5,
                color: '#81c784',
                name: '< 1M'
            }, {
                from: 1.5,
                to: 2,
                color: '#FFC428',
                name: '1M - 5M'
            }, {
                from: 2,
                to: 2.5,
                color: '#FF7987',
                name: '5M - 20M'
            }, {
                from: 2.5,
                color: '#FF2371',
                name: '> 20M'
            }]
        },
        tooltip: {
            headerFormat: '',
            formatter: function () {
                const point = this.point;
                const value = point.value;
                if (value < 0) {
                    return 'Data Collectors is not running for <b>' + this.point.name + '</b>';
                }

                let text = '';
                if (value < 1.5) {
                    text = 'No Spikes on <b>' + this.point.name + '</b>';
                } else {
                    const lastAlert = point.lastAlert;
                    text = 'Spike on <b>' + lastAlert.target + '/' + lastAlert.capture + '/' + lastAlert.metric + '/' + lastAlert.measurement + '</b>'
                        + '<br/>'
                        + '<b>' + value + '</b>'
                        + '<br/>'
                        + 'at '
                        + '<b>'
                        + formatDateTime(lastAlert.timestamp)
                        + '</b>';
                }


                if (point.prevMaxAlert != null) {
                    text = text + '<br/> Previous Spike:<b> ' + formatFloatNumber(point.prevMaxAlert.dataValueDouble) + '</b>';
                }

                return text;
            },
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    format: '{point.hc-a2}',
                    color: '#000000',
                    style: {
                        textOutline: false
                    }
                },
                point: {
                    events: {
                        click: function () {
                            // handleClickOnTarget(this.name, this.timestamp);
                            if (this.value < 0) {
                                // target is not running - do nothing
                                console.debug(`Target [${this.targetName}] is not running - do nothing`);
                                return;
                            }
                            // const timestamp = this.timestamp ? this.timestamp : null;
                            const targetName = this.name;
                            console.log("click on item " + targetName);

                            handleClickOnTarget(dashlet, targetName);
                        },
                        contextmenu: function (e) {
                            console.log("context menu!!!");
                            e.preventDefault();

                        },
                        dblclick: function () {
                            console.log("double click!!!")
                        }
                    }
                }
            }
        },

        series: [{
            borderWidth: 1,
            borderColor: 'rgba(206,217,224,.5)',
            name: '',
            data: seriesData
        }]
    };
}

/**
 * Filter dashlet data based on the settings (selected filters on the settings panel).
 *
 * @param dashletData dashlet data object
 * @param settings dashlet settings object
 * @returns {*}
 */
export const filterDashletData = (dashletData, settings) => {
    if (!settings) {
        return dashletData;
    }
    const {filterTargetName, filterDatabasePlatform, isHideDisconnectedTargets, filterSpikeValue} = settings;
    //filter by targetName
    let filteredDashletData = dashletData;
    if (filterTargetName) {
        const regex = convertWildcardStringToRegExp(filterTargetName);
        filteredDashletData = filteredDashletData.filter(function(item) {
            return regex.test(item.targetName);
        });
    }

    if (filterDatabasePlatform) {
        filteredDashletData = filteredDashletData.filter(item => item.databasePlatform === filterDatabasePlatform);
    }

    // hide disconnected targets
    if (isHideDisconnectedTargets) {
        filteredDashletData = filteredDashletData.filter(item => item.targetRunning);
    }

    // filter by spike value
    if (filterSpikeValue > 0) {
        const filteredDataBySpikeValue = [];

        filteredDashletData.forEach(item => {
            const itemAlerts = item.alerts;
            if (itemAlerts && itemAlerts[itemAlerts.length - 1] && itemAlerts[itemAlerts.length - 1].dataValueDouble >= filterSpikeValue) {
                filteredDataBySpikeValue.push(item);
            }
        });

        filteredDashletData = filteredDataBySpikeValue;
    }

    return filteredDashletData;
}

//todo: add docs
export const buildSpikePreviewChartConfig = (data, setTimeRange) => {
    if (!data) {
        return null;
    }

    // const charts = [convertChartSeriesList(data.chartSeriesList)[0]];
    const charts = convertChartSeriesList(data.chartSeriesList, setTimeRange);

    const chartAreaHeight = window.innerHeight - 70;
    const chartAreaWidth = window.innerWidth;
    // const chartAreaWidth = 1000;
    const chartHeight = chartAreaHeight / Math.min(5, charts.length);
    // const chartHeight = 700;

    const chartList = _map(charts.charts, (chartConfig) => {
        return  {
            ...chartConfig,
            chart: {
                ...chartConfig.chart,
                height: chartHeight,
                width: chartAreaWidth,
            },
            xAxis: {
                ...chartConfig.xAxis,
                type: 'datetime',
                minRange: 1,
                ordinal: false,
            },
            yAxis: {
                ...chartConfig.yAxis,
                opposite: false,
                type: 'logarithmic'
            },
            plotOptions: {
                ...chartConfig.plotOptions,
                series: chartConfig.plotOptions.series
            },
            tooltip: {
                ...chartConfig.tooltip,
                formatter: function() {
                  const info = this.series.userOptions;
                  if(info) {
                    let isHistorical = info.isHistorical;
                    let timeLabelTimestamp = (isHistorical ?  (this.x - info.historicalTimeOffset) : this.x);
                    let timeLabel = moment(timeLabelTimestamp).format('M/D/YYYY hh:mm:ss A');
                    if (this.point.spikeValue > 0) {
                        let confidenceScoreText = this.point.confidenceScore > 0 ? `, Confidence Score: ${Number(this.point.confidenceScore).toFixed(2)}` : '';
                        return `${this.series.name} : (${timeLabel}, <br> ${numeral(this.y).format("0,0.00")}, Anomaly Rank: ${Number(this.point.spikeValue).toFixed(2)}${confidenceScoreText})`;
                    }
                    return `${this.series.name} : (${timeLabel}, </br> ${numeral(this.y).format("0,0.00")} </br> )`;
                  } else {
                    let timeLabel = moment(this.x).format('M/D/YYYY hh:mm:ss A');
                    return `${this.series.name} : (${timeLabel}, </br> ${numeral(this.y).format("0,0.00")} </br> )`;
                  }
                },
                split: false
              }
        };
    });

    // Add spikes series
    const chartBagSeries = data.validationResultData.chartBagSeries;
    if (chartBagSeries) {
        chartList.forEach(chartConfigObject => {
            const chartElementKeys = _map(chartConfigObject.series, ser => ser.chartElementKey);
            chartElementKeys.forEach(chartElementKey => {
                const spikeSeriesList = chartBagSeries[chartElementKey];
                if (spikeSeriesList) {
                    let metricName;
                    spikeSeriesList.forEach(spikeSeries => {
                        let spikeSeriesData = [];
                        const filteredSeries = _.filter(chartConfigObject.series, ser => {
                          return ser.chartElementKey === chartElementKey;
                        });
                        if (filteredSeries && filteredSeries.length > 0) {
                          filteredSeries.forEach(series => {
                            if (series.target === spikeSeries.validationData.targetName
                              && series.capture === spikeSeries.validationData.capture
                              && series.metric === spikeSeries.validationData.metric
                              && series.measurement === spikeSeries.validationData.measurement) {
                                  spikeSeriesData = spikeSeries.validationData.data;
                                  metricName = series.metric;
                              }
                          });
                        }
                        if (spikeSeriesData.length > 0) {
                          let filteredValidationList = spikeSeriesData;
                          let existingSpikeSeriesIndex = chartConfigObject.series.findIndex(ser => ser.spikeChartElementKey === `${chartElementKey}_spikes_${metricName}`);
                          if (spikeSeriesData.length > 0) {
                            const formattedData = filteredValidationList.map(([timestamp, y, value, confidenceScore]) => ({
                                x: timestamp,
                                y: y,
                                spikeValue: value,
                                confidenceScore: confidenceScore
                            }));
                            const spikeSeriesObject = {
                              data: formattedData,
                              spikeChartElementKey: `${chartElementKey}_spikes_${metricName}`,
                              name: metricName,
                              type: 'scatter',
                              alertSeries: true,
                              marker: {
                                fillColor: 'rgba(0,0,0,0)',
                                enabled: true,
                                radius: 12,
                                symbol: 'circle',
                                lineWidth: 3,
                                lineColor: '#FF0000'
                              },
                              showInLegend: false
                            };
                
                            if (existingSpikeSeriesIndex >= 0) {
                              chartConfigObject.series[existingSpikeSeriesIndex] = spikeSeriesObject;
                            } else {
                              chartConfigObject.series.push(spikeSeriesObject);
                            }
                          }
                        }
                      })
                }
            })
        });
    }

    return chartList;
}

//todo: add docs
export const openSpikeInAnalyzer = (templateName) => (dispatch, getState) => {
    let startTime = getState().dashboard.startTime;
    let endTime = getState().dashboard.endTime;
    if (!startTime && !endTime) {
        window.open(HLC_URL + "?templateName=" + templateName, '_blank', 'noopener,noreferrer');
    } else {
        window.open(HLC_URL + "?templateName=" + templateName + "&startTime=" + startTime + "&endTime=" + endTime, '_blank', 'noopener,noreferrer');
    }
}

export const convertChartSeriesList = (seriesList, setTimeRange, currentChartsPage, chartElements, scheduledReportSeries) => {
    //return empty result if no charts series
    if (!seriesList || seriesList.length < 1) {
        return {
            isEmpty: true,
            config: null
        }
    }

    //create copy of chart series list
    const chartSeriesList = _map(seriesList, series => _cloneDeep(series));

    //find and set max values for each series in chartSeriesList
    _each(chartSeriesList, (chartSeriesListItem) => {
        chartSeriesListItem.maxValue = _max(_map(chartSeriesListItem.values, (point) => {
            return point[1];
        }));
    });

    const startTimeList = [];
    const endTimeList = [];
    let maxTime = null;
    let minTime = null;

    const groupedChartSeriesMap = groupChartSeriesList(chartSeriesList, "capture")

    //split chart groups to pages
    let chartDataPages = splitGroupsToPages(groupedChartSeriesMap, Object.keys(groupedChartSeriesMap).length);

    const currentPageGroups = chartDataPages[0];

    const convertedGroups = [];
    _each(currentPageGroups, (pageGroup) => {
        const keys = _keys(pageGroup);
        if (keys.length > 0) {
            const groupKey = keys[0];
            const groupArray = pageGroup[keys];

            const groupSeries = [];
            _each(groupArray, (groupArrayItem) => {
                const data = _slice(groupArrayItem.values);
                startTimeList.push(getMinTime(data));
                endTimeList.push(getMaxTime(data));
                const result = {
                    name: `${groupArrayItem.target}/${groupArrayItem.capture}/${groupArrayItem.metric}/${groupArrayItem.measurement}${groupArrayItem.isHistorical ? '/Historical' : ''}`,
                    data: data,
                    chartElementKey: groupArrayItem.chartElementKey,
                    isHistorical: groupArrayItem.isHistorical,
                    target: groupArrayItem.target,
                    capture: groupArrayItem.capture,
                    metric: groupArrayItem.metric,
                    measurement: groupArrayItem.measurement,
                    historicalTimeOffset: 0,
                };
                groupSeries.push(result)
            });
            convertedGroups.push({
                series: groupSeries,
                title: groupKey
            });
        }
    });

    minTime = _min(startTimeList) - 5000;
    maxTime = _max(endTimeList) + 5000;

    const charts = [];
    _each(convertedGroups, (group) => {
        const chart = {
            time: {
                useUTC: false,
            },
            boost: {
                useGPUTranslations: false,
                enabled: false,
                debug: {
                    timeSetup: true,
                    timeSeriesProcessing: true,
                    timeBufferCopy: true,
                    timeKDTree: true,
                    showSkipSummary: true
                }
            },

            credits: {
                enabled: false
            },

            rangeSelector: {
                enabled: false
            },
            navigator: {
                enabled: false,
            },
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            scrollbar: {
                enabled: false
            },
            xAxis: {
                min: minTime,
                max: maxTime,
                offset: 10,
            },
            yAxis: {
                title: {
                    text: null,
                },
                offset: 10,
            },
            plotOptions: {
                series: {
                    // cropThreshold: 0,
                    // boostThreshold: chartSeries[0].data.length + 1,
                    marker: {
                        enabled: true,
                        radius: 2
                    }
                }
            },
            title: {
                //todo: title!!!
                text: group.name,
                style: {
                    "fontSize": "14px"
                },
                // floating: true
            },
            chart: {
                style: {
                    fontFamily: 'Roboto'
                },
                resetZoomButton: {
                    theme: {
                        display: 'none'
                    }
                },
                type: 'line',
                // zoomType: 'xy',
                reflow: true,
                marginLeft: 70,
                marginRight:20,
                zoomType: 'xy', 
                events: {
                selection: function (e) {
                    if (e.xAxis) {
                        setTimeRange({ startTime: e.xAxis[0].min, endTime: e.xAxis[0].max });
                        this.xAxis[0].setExtremes(e.xAxis[0].min, e.xAxis[0].max, undefined, false, { trigger: 'selection' });
                        e.preventDefault();
                    }
                }
            }
            },
            tooltip: {
                xDateFormat: '%m/%d/%Y %H:%M:%S',
                shared: false,
                valueDecimals: 2
            },
            legend: {
                enabled: false
            },
            exporting: {
                sourceWidth: 500,
                sourceHeight: 110,
            },
            series: group.series
        };

        charts.push(chart);
    });

    return {
        isEmpty: false,
        charts: charts,
        scheduledReportSeries: scheduledReportSeries,
        minTime: minTime,
        maxTime: maxTime,
    };
}

const getMinTime = (data) => {
    let min = new Date().getTime();
    data.forEach((value) => {
        if(value[0] < min) {
            min = value[0];
        }
    })
    return min;
}

const getMaxTime = (data) => {
    let max = 0;
    data.forEach((value) => {
        if(value[0] > max) {
            max = value[0];
        }
    })
    return max;
}

/**
 * Find previous alert with max dataValueDouble from the list of alerts.
 *
 * @param alerts the list of alerts
 * @returns {*|{}|null}
 */
const getPreviousMaxAlert = (alerts) => {
    if (alerts.length > 1) {
        const lastAlert = _last(alerts);
        const lastAlertValue = lastAlert?.dataValueDouble;
        const maxAlert = _maxBy(_slice(alerts, 0, alerts.length - 1),
            d => {
                return d.dataValueDouble
            });

        if ((maxAlert.dataValueDouble - lastAlertValue) > 1 && maxAlert.dataValueDouble > 3) {
            return maxAlert;
        }
    }

    return null;
}

/**
 * Format number.
 *
 * @param number number
 * @returns {*}
 */
const formatNumber = (number) => {
    return numeral(number).format("0,0");
};

/**
 * Format decimal number.
 *
 * @param number decimal number
 * @returns {*}
 */
export const formatFloatNumber = (number) => {
    return numeral(number).format("0,0.00");
};

/**
 * Format DateTime.
 *
 * @param timestamp DateTime value
 * @returns {string}
 */
export const formatDateTime = (timestamp) => {
    return moment(timestamp).format('M/D/YYYY hh:mm:ss');
};

const convertWildcardStringToRegExp = (expression) => {
    const terms = expression.split('*');

    let trailingWildcard = false;

    let expr = '';
    for (let i = 0; i < terms.length; i++) {
        if (terms[i]) {
            if (i > 0 && terms[i - 1]) {
                expr += '.*';
            }
            trailingWildcard = false;
            expr += escapeRegExp(terms[i]);
        } else {
            trailingWildcard = true;
            expr += '.*';
        }
    }

    if (!trailingWildcard) {
        expr += '.*';
    }

    return new RegExp('^' + expr + '$', 'i');
}

const escapeRegExp = (str) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
import React from "react";
import {Box, Grid, List, ListItem, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import GetAppIcon from '@material-ui/icons/GetApp';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import UpdatesRestClient from "../../../../api/rest/UpdatesRestClient";

const UpdatesList: React.FC = () => {

    const handleDownloadFile = (fileName: string) => {
        window.open(UpdatesRestClient.createDownloadFileLink(fileName), "_blank");
    }

    return (
        <>
            <Box>
                <Grid container alignItems="center" justifyContent="space-between" style={{height: "48px"}}>
                    <Grid item xs>
                        <Typography variant="h6" id="tableTitle" component="div" style={{paddingLeft: "16px", textAlign: "left"}}>
                            Updates
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Paper>
                <List dense disablePadding>
                    <ListItem button>
                        <ListItemText primary="UpBeat Database Backend/hlc-db-backend.jar"/>
                        <ListItemSecondaryAction>
                            <Tooltip title="Download">
                                <IconButton color="primary" size="small" onClick={() => handleDownloadFile("hlc-db-backend.jar")}>
                                    <GetAppIcon fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem button>
                        <ListItemText primary="UpBeat Database Active Data Collector/DBActDC.jar"/>
                        <ListItemSecondaryAction>
                            <Tooltip title="Download">
                                <IconButton color="primary" size="small" onClick={() => handleDownloadFile("DBActDC.jar")}>
                                    <GetAppIcon fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Paper>
        </>
    );
}

export default UpdatesList;

import React from "react";
import Integration from "types/model/settings/integration/Integration";
import IntegrationType from "types/model/settings/integration/IntegrationType";
import IntegrationSection from "../IntegrationSection";
import EmailIntegrationSection from "./sections/EmailIntegrationSection";
import EmailIntegration from "types/model/settings/integration/EmailIntegration";

interface LegacyChannelsIntegrationsProps {
    integrations: Record<string, Integration>;
    onSaveIntegration: (integration: Integration) => void;
    onTestIntegration: (integration: Integration) => void;
}

const LegacyChannelsIntegrations: React.FC<LegacyChannelsIntegrationsProps> = ({
                                                                 integrations,
                                                                 onSaveIntegration,
                                                                 onTestIntegration
                                                             }) => (
    <>
        {integrations[IntegrationType.EMAIL]
            && <IntegrationSection
                title={integrations[IntegrationType.EMAIL].title}
                content={
                    <EmailIntegrationSection
                        integration={integrations[IntegrationType.EMAIL] as EmailIntegration}
                        onSave={onSaveIntegration}
                        onTest={onTestIntegration}
                    />
                }
            />}
    </>
);

export default LegacyChannelsIntegrations;

import React, { useState } from "react";
import MSTeamsIntegration from "types/model/settings/integration/MSTeamsIntegration";
import useIntegrationSectionState from "hooks/integration/useIntegrationSectionState";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "../../../../../components/common/Switch/Switch";
import TextField from "../../../../../components/common/TextField/TextField";
import SubmitButton from "../../../../../components/common/Button/SubmitButton";

interface MSTeamsIntegrationSectionProps {
    integration: MSTeamsIntegration;
    onSave: (integration: MSTeamsIntegration) => void;
}

interface ErrorMessages {
    clientId: string | null;
    clientSecret: string | null;
    tenantId: string | null;
    teamId: string | null;
    channelId: string | null;
    driveId: string | null;
}
const MSTeamsIntegrationSection: React.FC<MSTeamsIntegrationSectionProps> = ({
                                                                                 onSave,
                                                                                 ...props
                                                                             }) => {
    const { integration, handleChangeProperty, handleEnabledToggle }
        = useIntegrationSectionState(props.integration);
    const {
        enabled,
        clientId,
        clientSecret,
        tenantId,
        teamId,
        channelId,
        driveId

    }
        = integration as MSTeamsIntegration;

    const [errors, setErrors] = useState<ErrorMessages>({
        clientId: null,
        clientSecret: null,
        tenantId: null,
        teamId: null,
        channelId: null,
        driveId: null,
    });
    const handleSave = () => {
        let hasError = false;
        const newErrors: ErrorMessages = { clientId: null, clientSecret: null, tenantId: null, teamId: null, channelId: null, driveId: null };
        if (enabled) {
            if (!clientId) {
                newErrors.clientId = "Client Id cannot be empty.";
                hasError = true;
            }
            if (!clientSecret) {
                newErrors.clientSecret = "Client Secret cannot be empty.";
                hasError = true;
            }
            if (!tenantId) {
                newErrors.tenantId = "Tenant Id cannot be empty.";
                hasError = true;
            }
            if (!teamId) {
                newErrors.teamId = "Team Id cannot be empty.";
                hasError = true;
            }
            if (!channelId) {
                newErrors.channelId = "Channel Id cannot be empty.";
                hasError = true;
            }
            if (!driveId) {
                newErrors.driveId = "Drive Id cannot be empty.";
                hasError = true;
            }
            setErrors(newErrors);
        }
        if (!hasError) {
            newErrors.clientId = "";
            setErrors(newErrors);
            onSave(integration as MSTeamsIntegration);
        }
    };
    return (
        <>
            <Grid
                container
                spacing={2}
                direction="column"
                justifyContent="flex-start"
                style={{marginTop: "8px", marginBottom: "8px"}}
            >
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="enable">Enabled</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <Switch checked={enabled || false}
                                onChange={handleEnabledToggle}
                                name="enabled"
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="clientId">Client ID</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            disabled={!enabled}
                            name="clientId"
                            fullWidth
                            textAlign="left"
                            value={clientId}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                        {enabled && errors.clientId && <div style={{ color: 'red', marginTop: '4px', textAlign: 'left' }}>{errors.clientId}</div>}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="clientSecret">Client Secret</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            disabled={!enabled}
                            name="clientSecret"
                            fullWidth
                            textAlign="left"
                            value={clientSecret}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                        {enabled && errors.clientSecret && <div style={{ color: 'red', marginTop: '4px', textAlign: 'left' }}>{errors.clientSecret}</div>}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="tenantId">Tenant ID</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            disabled={!enabled}
                            name="tenantId"
                            fullWidth
                            textAlign="left"
                            value={tenantId}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                        {enabled && errors.tenantId && <div style={{ color: 'red', marginTop: '4px', textAlign: 'left' }}>{errors.tenantId}</div>}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="teamId">Team ID</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            disabled={!enabled}
                            name="teamId"
                            fullWidth
                            textAlign="left"
                            value={teamId}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                        {enabled && errors.teamId && <div style={{ color: 'red', marginTop: '4px', textAlign: 'left' }}>{errors.teamId}</div>}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="channelId">Channel ID</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            disabled={!enabled}
                            name="channelId"
                            fullWidth
                            textAlign="left"
                            value={channelId}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                        {enabled && errors.channelId && <div style={{ color: 'red', marginTop: '4px', textAlign: 'left' }}>{errors.channelId}</div>}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="driveId">Drive ID</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            disabled={!enabled}
                            name="driveId"
                            fullWidth
                            textAlign="left"
                            value={driveId}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                        {enabled && errors.driveId && <div style={{ color: 'red', marginTop: '4px', textAlign: 'left' }}>{errors.driveId}</div>}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={7}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center">
                    <Grid item xs={1}>
                        {/*@ts-ignore*/}
                        <SubmitButton onClick={handleSave}>Save</SubmitButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default MSTeamsIntegrationSection;

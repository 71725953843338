import {createSlice} from "@reduxjs/toolkit";
import * as _ from "lodash";
import {newDashboardObject} from "../../objects/dashboardObjects";
import TargetDatabaseType from "types/model/common/TargetDatabaseType";

const initialState = {
    isTreeDataLoading: false,

    dashboardTree: {
        entities: {
            dashboardGroups: {},
            dashboards: {},
            dashlets: {},
        },
        result: [],
        dashlets: {}
    },
    dashletsData: {},
    activeDashboardId: null,
    editDashboardGroup: null,
    chartData: null,
    isChartDialogOpen: false,
    creatingDashlet: {},

    isDashletPreviewOpen:false,
    selectedDashletId: null,
    dashboardModal: null,
    isChartDataLoading: false,
    isLoadingTemplateNames: false,
    isLoadingReportNames: false,
    isLoadingTemplates: false,
    targetConfiguration: {
        targetName: "",
        databaseType: TargetDatabaseType.MySQL,
        databaseHost: null,
        databasePort: null,
        databaseName: null,
        databaseUser: null,
        databasePassword: null,
        databaseUrl: null,
        useDatabaseUrl: false,
        hidePassword: true
    },
    anomalyDashboard: {},
    startTime: null,
    endTime: null,
    quickDahboardConfig: {
        isQuickDahboardModalOpen: false, 
    },
};

const targetConfigurationInitialState = {
    targetName: "",
    databaseType: TargetDatabaseType.MySQL,
    databaseHost: null,
    databasePort: null,
    databaseName: null,
    databaseUser: null,
    databasePassword: null,
    databaseUrl: null,
    useDatabaseUrl: false,
  };

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setDashboardTree(state, action) {
            state.dashboardTree = action.payload;
            state.isDashboardTreeLoading = false;
        },
        setIsDashboardTreeLoading(state, action) {
            state.isDashboardTreeLoading = action.payload;
        },
        toggleDashboardGroup(state, action) {
            const dashboardGroupId = action.payload;
            state.dashboardTree.entities.dashboardGroups[dashboardGroupId].isOpened =
                !state.dashboardTree.entities.dashboardGroups[dashboardGroupId].isOpened;
        },
        setEditDashboardGroup(state, action) {
            state.editDashboardGroup = action.payload;
        },
        setCreateDashlet: {
            reducer(state, action) {
                const {dashletId, data} = action.payload;
                state.creatingDashlet[dashletId] = data;
              },
              prepare(dashletId, data) {
                return {
                  payload: {dashletId, data}
                }
              },
        },
        updateCreateDashlet: {
            reducer(state, action) {
                const {dashletId, data} = action.payload;
                state.creatingDashlet[dashletId] = data;
              },
              prepare(dashletId, data) {
                return {
                  payload: {dashletId, data}
                }
              },
        },
        addDashboardGroup(state, action) {
            const dashboardGroup = action.payload;
            dashboardGroup.dashboards = [];

            // Ensure that the entities and dashboardGroups objects are initialized
            if (!state.dashboardTree.entities) {
                state.dashboardTree.entities = {};
            }

            if (!state.dashboardTree.entities.dashboardGroups) {
                state.dashboardTree.entities.dashboardGroups = {};
            }

            state.dashboardTree.entities.dashboardGroups[dashboardGroup.id] = dashboardGroup;

            // Ensure that the result array is initialized
            if (!Array.isArray(state.dashboardTree.result)) {
                state.dashboardTree.result = [];
            }

            state.dashboardTree.result.push(dashboardGroup.id);
        },
        removeDashboardGroupFromTree(state, action) {
            const dashboardGroupId = action.payload;
            const dashboardIds = state.dashboardTree.entities.dashboardGroups[dashboardGroupId].dashboards;
            const dashletIds = _.flatten(dashboardIds.map((dashboardId) => state.dashboardTree.entities.dashboards[dashboardId].dashlets));

            delete state.dashboardTree.entities.dashboardGroups[dashboardGroupId];
            _.forEach(dashboardIds, (dashboardId) => {
                delete state.dashboardTree.entities.dashboards[dashboardId];
            });
            _.forEach(dashletIds, (dashletId) => {
                delete state.dashboardTree.entities.dashlets[dashletId];
                delete state.dashletsData[dashletId];
            });
            _.pull(state.dashboardTree.result, dashboardGroupId);
            state.activeDashboardId = !_.includes(dashboardIds, state.activeDashboardId) ? state.activeDashboardId : null
        },
        setUpdatedDashboardGroup(state, action) {
            const dashboardGroup = action.payload;
            state.dashboardTree.entities.dashboardGroups[dashboardGroup.id] = {
                ...state.dashboardTree.entities.dashboardGroups[dashboardGroup.id],
                ...dashboardGroup
            };
            state.editDashboardGroup = null;
        },
        setActiveDashboardId(state, action) {
            state.activeDashboardId = action.payload;
        },
        addDashboardToTree(state, action) {
            const dashboard = action.payload;
            const {id, dashboardGroupId} = dashboard;
            state.dashboardTree.entities.dashboardGroups[dashboardGroupId].dashboards.push(id);
            state.dashboardTree.entities.dashboardGroups[dashboardGroupId].isOpened = true;
            state.dashboardTree.entities.dashboards[id] = {
                ...dashboard,
                accordionPanels: {},
                dashlets: []
            };
            state.dashboardModal = null;
        },
        removeDashboardFromTree(state, action) {
            const dashboardId = action.payload;
            const dashboardGroupId = state.dashboardTree.entities.dashboards[dashboardId].dashboardGroupId;
            const dashletIds = state.dashboardTree.entities.dashboards[dashboardId].dashlets;
            _.pull(state.dashboardTree.entities.dashboardGroups[dashboardGroupId].dashboards, dashboardId);
            delete state.dashboardTree.entities.dashboards[dashboardId]
            _.forEach(dashletIds, (dashletId) => {
                delete state.dashboardTree.entities.dashlets[dashletId];
                delete state.dashletsData[dashletId];
            });
            state.activeDashboardId = (dashboardId !== state.activeDashboardId) ? state.activeDashboardId : null;
        },
        setDashboardLayout(state, action) {
            const {dashboardId, layoutConfig} = action.payload;
            state.dashboardTree.entities.dashboards[dashboardId].layoutConfig = layoutConfig;
        },
        setDashletData: {
            reducer(state, action) {
                const {dashletId, dashletData} = action.payload;
                state.dashletsData[dashletId] = dashletData;
            },
            prepare(dashletId, dashletData) {
                return {
                    payload: {dashletId, dashletData}
                }
            },
        },
        setChartsData(state, action) {
            const dashletData = action.payload;
            state.chartData = dashletData;
        },
        openDashletPreview(state, action){
            state.isDashletPreviewOpen = true;
            state.selectedDashletId = action.payload;
        },
        setIsChartDataLoading(state, action) {
            state.isChartDataLoading = action.payload
        },
        closeDashletPreview(state, action){
            state.isDashletPreviewOpen = false;
            state.selectedDashletId = null;
        },
        openChartDialog(state, action) {
            state.isChartDialogOpen = true;
        },
        closeChartDialog(state,action) {
            state.isChartDialogOpen = false;
            state.chartData = null;
        },
        addDashletsToTree(state, action) {
            const dashlets = action.payload;
            dashlets.forEach(dashlet => {
                const { id, dashboardId, accordionId } = dashlet;
                if (accordionId != null && state.dashboardTree.entities.dashboards[dashboardId].accordionPanels[accordionId]) {
                    state.dashboardTree.entities.dashboards[dashboardId].accordionPanels[accordionId].dashlets.push(id);
                }
                if (state.dashboardTree.entities.dashboards[dashboardId]) {
                    state.dashboardTree.entities.dashboards[dashboardId].dashlets.push(id);
                }
                state.dashboardTree.entities.dashlets[id] = dashlet;
            });
        },
        addDashletToTree(state, action) {
            let dashlet = action.payload;
            const {id, dashboardId, accordionId} = dashlet;
            state.dashboardTree.entities.dashboards[dashboardId].accordionPanels[accordionId].dashlets.push(id);
            state.dashboardTree.entities.dashboards[dashboardId].dashlets.push(id);
            state.dashboardTree.entities.dashlets[id] = dashlet;
        },
        updateDashletData(state, action) {
            let dashlet = action.payload;
            const {id} = dashlet;
            state.dashboardTree.entities.dashlets[id] = dashlet;
        },
        setDashboardModal(state, action) {
            state.dashboardModal = action.payload;
        },
        openNewDashboard(state, action) {
            state.dashboardModal = {
                ...newDashboardObject(),
                dashboardGroupId: action.payload
            }
        },
        setQuickDashboardModal(state) {
            state.dashboardModal = {
                ...newDashboardObject(),
            }
        },
        setUpdatedDashboard(state, action) {
            const dashboard = action.payload;
            state.dashboardTree.entities.dashboards[dashboard.id] = {
                ...state.dashboardTree.entities.dashboards[dashboard.id],
                ...dashboard
            };
            state.dashboardModal = null;
        },
        removeDashletFromTree(state, action) {
            const dashletId = action.payload;
            const dashboardId = state.dashboardTree.entities.dashlets[dashletId].dashboardId;
            const accordionId = state.dashboardTree.entities.dashlets[dashletId].accordionId;
            _.pull(state.dashboardTree.entities.dashboards[dashboardId].dashlets, dashletId);
            _.pull(state.dashboardTree.entities.dashboards[dashboardId].accordionPanels[accordionId].dashlets, dashletId);
            delete state.dashboardTree.entities.dashlets[dashletId]
            delete state.dashletsData[dashletId]
        },
        setAccordionPanels(state, action) {
            const {id, dashboardId} = action.payload;
            delete state.dashboardTree.entities.dashboards[dashboardId].accordionPanels[id];
        },
        setDashletTimeRange: {
            reducer(state, action) {
                const {dashletId, timeRange} = action.payload;
                state.dashboardTree.entities.dashlets[dashletId].timeRange = timeRange;
            },
            prepare(dashletId, timeRange) {
                return {
                    payload: {dashletId, timeRange}
                }
            },
        },
        setDashletSettings: {
            reducer(state, action) {
                const {dashletId, settings} = action.payload;
                state.dashboardTree.entities.dashlets[dashletId].settings = settings;
            },
            prepare(dashletId, settings) {
                return {
                    payload: {dashletId, settings}
                }
            },
        },
        setDashletConfig: {
            reducer(state, action) {
                const {dashletId, config} = action.payload;
                state.dashboardTree.entities.dashlets[dashletId].config = config;
            },
            prepare(dashletId, config) {
                return {
                    payload: {dashletId, config}
                }
            },
        },
        setDashboardModalProperty: {
            reducer(state, action) {
                const {propertyName, propertyValue} = action.payload;
                state.dashboardModal[propertyName] = propertyValue;
            },
            prepare(propertyName, propertyValue) {
                return {
                    payload: {propertyName, propertyValue}
                }
            },
        },
        setEditDashboardGroupProperty: {
            reducer(state, action) {
                const {propertyName, propertyValue} = action.payload;
                state.editDashboardGroup[propertyName] = propertyValue;
            },
            prepare(propertyName, propertyValue) {
                return {
                    payload: {propertyName, propertyValue}
                }
            },
        },
        setExtremes: {
            reducer(state, action) {
                const {key, xMin, xMax, yMin, yMax} = action.payload;
                state.chartData = {
                    ...state.chartData,
                    xMin: xMin,
                    xMax: xMax
                };
              },
              prepare(key, xMin, xMax, yMin, yMax) {
                return {
                  payload: {key, xMin, xMax, yMin, yMax}
                };
            },
        },
        updateAccordions(state, action) {
            const resultObj = action.payload;
            delete resultObj.dashlets;
            state.dashboardTree.entities.dashboards[state.activeDashboardId].accordionPanels[resultObj.id] = {
                ...state.dashboardTree.entities.dashboards[state.activeDashboardId].accordionPanels[resultObj.id],
                ...resultObj
            }
        },
        onAddAccordion(state, action) {
            let accordion = {
                ...action.payload,
                dashlets: []
            };
            const { id, dashboardId } = accordion;
            state.dashboardTree.entities.dashboards[dashboardId].accordionPanels[id] = accordion;
        },
        setTargetConfiguration: {
            reducer(state, action) {
                const { propertyName, propertyValue } = action.payload;
                state.targetConfiguration[propertyName] = propertyValue;
              },
              prepare(propertyName, propertyValue) {
                return {
                  payload: { propertyName, propertyValue },
                };
              },
        },
        unSetTargetConfiguration(state) {
            state.targetConfiguration = {
                ...targetConfigurationInitialState
            }
        },
        setSelectedDashletId(state, action) {
            state.selectedDashletId = action.payload;
        },
        toggleIsLoadingTemplateNames(state, action) {
            state.isLoadingTemplateNames = action.payload;
        },
        toggleIsLoadingReportNames(state, action) {
            state.isLoadingReportNames = action.payload;
        },
        toggleIsLoadingTemplates(state, action) {
            state.isLoadingTemplates = action.payload;
        },
        addAnomalyDashboard(state, action) {
            const { dashboardId, anomalySpike } = action.payload;
            const anomalyDashboard =  state.anomalyDashboard[dashboardId];
            if (Object.keys(anomalySpike).length > 0) {
                if (!anomalyDashboard) {
                    state.anomalyDashboard[dashboardId] = anomalySpike;
                } else {
                    Object.keys(anomalyDashboard).forEach(existedTargetName => {
                        if (!Object.keys(anomalySpike).includes(existedTargetName)) {
                            state.anomalyDashboard[dashboardId][existedTargetName] = {}
                        }
                    })
                    Object.keys(anomalySpike).forEach(newTargetName => {
                        state.anomalyDashboard[dashboardId][newTargetName] = anomalySpike[newTargetName];
                    });
                }
            } else {
                state.anomalyDashboard[dashboardId] = {}
            }
            // state.anomalyDashboard[dashboardId] = anomalySpike;
        },
        setTimeRanges(state, action){
            const { startTime, endTime } = action.payload;
            state.startTime = startTime;
            state.endTime = endTime;
        },
        toogleIsQuickDahboardModalOpen(state) {
            state.quickDahboardConfig.isQuickDahboardModalOpen = !state.quickDahboardConfig.isQuickDahboardModalOpen;
        }
    }
})

export const {
    setDashboardTree,
    setIsDashboardTreeLoading,
    toggleDashboardGroup,
    setEditDashboardGroup,
    addDashboardGroup,
    removeDashboardGroupFromTree,
    setUpdatedDashboardGroup,
    setActiveDashboardId,
    addDashboardToTree,
    removeDashboardFromTree,
    setDashboardLayout,
    setDashletData,
    openDashletPreview,
    closeDashletPreview,
    openChartDialog,
    closeChartDialog,
    setChartsData,
    addDashletToTree,
    addDashletsToTree,
    setDashboardModal,
    openNewDashboard,
    setUpdatedDashboard,
    removeDashletFromTree,
    setDashletTimeRange,
    setDashletConfig,
    setDashletSettings,
    setDashboardModalProperty,
    setEditDashboardGroupProperty,
    updateAccordions,
    setExtremes,
    setCreateDashlet,
    updateCreateDashlet,
    updateDashletData,
    setIsChartDataLoading,
    onAddAccordion,
    setAccordionPanels,
    setTargetConfiguration,
    unSetTargetConfiguration,
    setSelectedDashletId,
    toggleIsLoadingTemplateNames,
    toggleIsLoadingReportNames,
    toggleIsLoadingTemplates,
    toggeleIsLoadingTargetNames,
    addAnomalyDashboard,
    setTimeRanges,
    toogleIsQuickDahboardModalOpen,
    setQuickDashboardModal
} = dashboardSlice.actions;        


export default dashboardSlice.reducer;

export const selectIsDashletPreviewOpen = state => state.dashboard.isDashletPreviewOpen;
export const selectDashletData = (state, dashletId) => state.dashboard.dashletsData[dashletId];

export const selectDashletTimeRange = (state, dashletId) =>
    state.dashboard.dashboardTree.entities.dashlets[dashletId].timeRange

export const selectDashlet = (state, dashletId) => state.dashboard.dashboardTree.entities.dashlets[dashletId];

export const selectHeatmapHistoryDashlet = (state, heatmpaDashletId) => {
    const dashlets = state.dashboard.dashboardTree.entities.dashlets;
    if (dashlets) {
        for (const dashletId in dashlets) {
            if (dashlets.hasOwnProperty(dashletId)) {
                if (dashlets[dashletId].config.connectedHeatmapDashletId && 
                    dashlets[dashletId].config.connectedHeatmapDashletId === heatmpaDashletId) {
                    return dashletId;
                }
            }
        }
    }
}

export const selectCraeteDashletList = (state, dashletId) => state.dashboard.creatingDashlet[dashletId]

    
export const selectSelectedDashletId = (state) => state.dashboard.selectedDashletId;

export const selectIsChartDataLoading = (state) => state.dashboard.isChartDataLoading;

export const selectIsLoadingTemplateNames = (state) => state.dashboard.isLoadingTemplateNames;

export const selectIsLoadingReportNames = (state) => state.dashboard.isLoadingReportNames;

export const selectIsLoadingTemplates = (state) => state.dashboard.isLoadingTemplates;

export const selectIsLoadingTargetNames = (state) => state.dashboard.isLoadingTargetNames;

export const removeAccordionPanel = accordion => (dispatch, getState) => {
    const state = getState();
    dispatch(setAccordionPanels(accordion))
    dispatch(setActiveDashboardId(accordion.dashboardId))
}
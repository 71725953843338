export const HOME_PATH = '/start';
export const LOGOUT_PATH = '/logout';
export const CHANGE_PASSWORD_PATH = '/changePassword';
export const SUBSCRIPTION_PATH = '/subscription';
export const INTEGRATIONS = "/integrations";
export const INTEGRATIONS_APM = "/integrations/apm";
export const INTEGRATIONS_TICKETING = "/integrations/ticketing";
export const INTEGRATIONS_BOTS = "/integrations/bots";
export const INTEGRATIONS_LEGACY_CHANNELS = "/integrations/legacy-channels";
export const INTEGRATIONS_GEN_AI = "/integrations/gen-ai";
export const INTEGRATIONS_OTHER = "/integrations/other";

export const BACKENDS = "/backends";
export const BACKENDS_LIST = "/backends/list"
export const BACKENDS_ADD = "/backends/add";
export const BACKENDS_LOGS = "/backends/logs";
export const BACKENDS_COLLECTORS = "/backends/collectors";
export const BACKENDS_UPDATES = "/backends/updates";

export const JOBS = "/jobs";
export const JOBS_LIST = "/jobs/list";

export const LOGIN_PATH = '/';
export const REGISTRATION_PATH = '/registration';
export const FORGOT_PASSWORD_PATH = '/forgotPassword';
export const FORGOT_PASSWORD_FINISH_PATH = '/forgotPassword/finish';
export const CHANGE_EMAIL_CONFIGURATION = '/emailConfiguration'

export const DASHBOARD_HOME_PATH = '/dashboard';
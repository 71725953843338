import {
    DashletConfigDropdownOption
} from 'components/dashboard/dashlets/DashletConfigDropdown/DashletConfigDropdown';
import HLCBackend from "types/HLCBackend";

export const backendAsOption = (backend: HLCBackend): DashletConfigDropdownOption => {
    return {
        value: backend.backendKey,
        label: backend.name
    }
}

export const stringAsOption = (str: string, isSetIcon?: boolean): DashletConfigDropdownOption => {
    return {
        value: str,
        label: str,
        isSetIcon: isSetIcon !== undefined ? isSetIcon : false
    }
}
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Backdrop,
    CircularProgress,
    Input
} from "@material-ui/core";
import DialogTitle from "components/common/DialogTitle/DialogTitle";
import TargetDatabaseType from 'types/model/common/TargetDatabaseType';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { REGULAR_DASHBOARD, ANOMALY_DASHBOARD, DEFAULT_DASHBOARD } from 'dashlets/AnomalyDetectionDashlet/constants';
import HLCDashletsRestClient from 'api/rest/HLCDashletsRestClient';
import { listTargetNames } from 'dashlets/actions';
import { LIST_TARGET_NAMES_SUBSCRIPTION } from 'dashlets/hlcDashletWebSocketClient';
import HLCUserSessionWebSocketSubscription from 'websocket/HLCUserSessionWebSocketSubscription';
import { setDashletOptionsProperty } from 'reducers/dashboard/createDashletSlice';

const styles = theme => ({
    root: {
        maxWidth: '700px',
    },
    dialogContent: {
        width: '400px',
    },
    actionButtonLabel: {
        fontWeight: 600,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    toggleButton: {
        padding: '8px',
        gap: '8px'
    }
});

const DashboardModal = (props) => {
    const { classes, open, handleClose, handleConfirm, isLocked, errors, objectData, handleChangeInput, backendKey, 
        dashboardType, handleChangeProperty, handleBackendSelect, handleDashboardTypeChange, createNewTarget, targetName, targetNames } = props;
    const [backends, setBackends] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        // Fetch the backends when the component mounts
        HLCDashletsRestClient.listUserBackends()
            .then(backends => {
                setBackends(backends);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        if (backendKey) {
            dispatch(listTargetNames(backendKey));
        }
    }, [backendKey, dispatch]);

    const {
        targetConfiguration: {
            databaseType,
            databaseHost,
            databasePort,
            databaseName,
            databaseUser,
            databasePassword,
            databaseUrl,
            useDatabaseUrl,
            hidePassword
        },
    } = props;

    const isEditMode = Boolean(objectData.id);
    // const isDisabled = !databaseType || useDatabaseUrl || !backendKey;
    const isDisabled = !backendKey || !createNewTarget;

    const handleListTargetNamesStompResponse = useCallback((response) => {
        dispatch(setDashletOptionsProperty({propertyName: "targetOptions", propertyValue: response.options}))
    }, []);

    return (
        <>
        <HLCUserSessionWebSocketSubscription
        subscriptionDestination={LIST_TARGET_NAMES_SUBSCRIPTION}
        handleMessage={handleListTargetNamesStompResponse}
        />
        <Dialog
            open={open}
            onClose={handleClose}
            onEnter={props.onOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" onClose={handleClose}>
                {isEditMode ? 'Edit Dashboard' : 'Create Dashboard'}
            </DialogTitle>

            <Backdrop className={classes.backdrop} open={isLocked}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <DialogContent className={classes.dialogContent}>

                <FormControl key="dash-name" fullWidth required error={errors.name} margin="dense">
                    <InputLabel id="name-label" htmlFor="dash-name">Dashboard Name</InputLabel>
                    <Input
                        id="dash-name"
                        name="name"
                        type="text"
                        value={objectData.name}
                        onChange={handleChangeInput}
                    />
                </FormControl>

                <FormControl variant="outlined" size="small" fullWidth margin="normal">
                    <InputLabel id="dashboard-type-label">Dashboard Type</InputLabel>
                    <Select
                        labelId="dashboard-type-label"
                        id="dashboardType"
                        name="dashboardType"
                        value={dashboardType}
                        onChange={handleDashboardTypeChange}
                        label="Dashboard Type"
                        fullWidth
                    >
                        <MenuItem value={REGULAR_DASHBOARD}>Regular Dashboard</MenuItem>
                        <MenuItem value={ANOMALY_DASHBOARD}>Anomaly Dashboard</MenuItem>
                        <MenuItem value={DEFAULT_DASHBOARD}>Default Dashboard</MenuItem>
                    </Select>
                </FormControl>
                {dashboardType === ANOMALY_DASHBOARD && (
                        <Grid item xs={12}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel id="hlc-backend">Select Upbeat Backend</InputLabel>
                                <Select
                                    labelId="dashlet-hlc-chart-backendKey-label-1582673886219"
                                    id="backendKey"
                                    name="backendKey"
                                    value={backendKey ? backendKey : ''}
                                    onChange={handleBackendSelect}
                                    label="Select Upbeat Backend"
                                    fullWidth
                                >
                                    <MenuItem key="dashlet-hlc-chart-backendKey-menu-item-1582673956819" value=""></MenuItem>
                                    {_.map(backends, (backend) => (
                                        <MenuItem
                                            key={`dashlet-hlc-chart-backendKey-menu-item-key-${backend.backendKey}`}
                                            value={backend.backendKey}>
                                            {backend.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                )}
                <Grid
                    container
                    direction="row"
                    alignItems="center">
                    {dashboardType === DEFAULT_DASHBOARD && (
                        <div>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" size="small" fullWidth>
                                    <InputLabel id="hlc-backend">Select Upbeat Backend</InputLabel>
                                    <Select
                                        labelId="dashlet-hlc-chart-backendKey-label-1582673886219"
                                        id="backendKey"
                                        name="backendKey"
                                        value={backendKey ? backendKey : ''}
                                        onChange={handleBackendSelect}
                                        label="Select Upbeat Backend"
                                        fullWidth
                                    >
                                        <MenuItem key="dashlet-hlc-chart-backendKey-menu-item-1582673956819" value=""></MenuItem>
                                        {_.map(backends, (backend) => (
                                            <MenuItem
                                                key={`dashlet-hlc-chart-backendKey-menu-item-key-${backend.backendKey}`}
                                                value={backend.backendKey}>
                                                {backend.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl margin="dense" variant="outlined" size="small" fullWidth>
                                    <InputLabel id="database-type-label">Select Target</InputLabel>
                                    <Select
                                        id="targetName"
                                        name="targetName"
                                        labelId="target-name-label"
                                        value={targetName}
                                        onChange={handleChangeInput}
                                        label="Target Name"
                                        disabled={createNewTarget}
                                        fullWidth
                                    >
                                        {targetNames?.map((targetName) => (
                                            <MenuItem key={`name-${targetName}`} value={targetName}>
                                                {targetName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color='primary'
                                            id="createNewTarget"
                                            name="createNewTarget"
                                            size="small"
                                            checked={createNewTarget}
                                            onChange={(event) => handleChangeInput(event)}
                                        />
                                    }
                                    label="Is Create Dashboard With Default Target"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl margin="dense" variant="outlined" size="small" fullWidth>
                                    <InputLabel id="database-type-label">Database Type</InputLabel>
                                    <Select
                                        id="databaseType"
                                        name="databaseType"
                                        labelId="database-type-label"
                                        value={databaseType}
                                        onChange={handleChangeInput}
                                        disabled={isDisabled}
                                        label="Database Type"
                                        fullWidth
                                    >
                                        {Object.values(TargetDatabaseType).map((dbType) => (
                                            <MenuItem key={`db-type-${dbType}`} value={dbType}>
                                                {dbType}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item container spacing={1}>
                                <Grid item xs={8}>
                                    <TextField
                                        required
                                        error={errors.databaseHost}
                                        id="databaseHost"
                                        name="databaseHost"
                                        label="Host"
                                        variant="outlined"
                                        fullWidth
                                        margin="dense"
                                        size="small"
                                        value={databaseHost}
                                        onChange={handleChangeInput}
                                        disabled={isDisabled}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        error={errors.databasePort}
                                        id="databasePort"
                                        name="databasePort"
                                        label="Port"
                                        variant="outlined"
                                        fullWidth
                                        margin="dense"
                                        size="small"
                                        value={databasePort}
                                        onChange={handleChangeInput}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item container spacing={1}>
                                <Grid item xs={6}>
                                    <TextField
                                        required 
                                        error={errors.databaseUser}
                                        id="databaseUser"
                                        name="databaseUser"
                                        label="Username"
                                        variant="outlined"
                                        fullWidth
                                        margin="dense"
                                        size="small"
                                        value={databaseUser}
                                        onChange={handleChangeInput}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required 
                                        error={errors.databasePassword}
                                        id="databasePassword"
                                        name="databasePassword"
                                        label="Password"
                                        type="password"
                                        variant="outlined"
                                        fullWidth
                                        margin="dense"
                                        size="small"
                                        value={databasePassword}
                                        onChange={handleChangeInput}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="hidePassword"
                                            name="hidePassword"
                                            size="small"
                                            color='primary'
                                            checked={hidePassword}
                                            onChange={(event) => {
                                                handleChangeProperty(event.target.name, event.currentTarget.checked);
                                            }}
                                        />
                                    }
                                    label="Hide Password"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    required 
                                    error={errors.databaseName}
                                    id="databaseName"
                                    name="databaseName"
                                    label="Database"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={databaseName}
                                    onChange={handleChangeInput}
                                    disabled={isDisabled}
                                />
                            </Grid>

                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color='primary'
                                            id="useDatabaseUrl"
                                            name="useDatabaseUrl"
                                            size="small"
                                            checked={useDatabaseUrl}
                                            disabled={isDisabled}
                                            onChange={(event) => {
                                                handleChangeProperty(event.target.name, event.currentTarget.checked);
                                            }}
                                        />
                                    }
                                    label="Use Database/JDBC URL"
                                />
                            </Grid>

                            <Grid item>
                                <TextField
                                    required={useDatabaseUrl} 
                                    error={errors.databaseUrl}
                                    multiline
                                    maxRows={5}
                                    id="databaseUrl"
                                    name="databaseUrl"
                                    label="Database/JDBC URL"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    disabled={!useDatabaseUrl || !backendKey || isDisabled}
                                    value={databaseUrl}
                                    onChange={handleChangeInput}
                                />
                            </Grid>
                        </div>
                    )}
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" classes={{
                    label: classes.actionButtonLabel
                }}>
                    Cancel
                </Button>
                <Button onClick={handleConfirm} color="primary" classes={{
                    label: classes.actionButtonLabel
                }}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
        </>
    );
};

DashboardModal.propTypes = {
    // data
    classes: PropTypes.object.isRequired,
    objectData: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    isLocked: PropTypes.bool,
    open: PropTypes.bool,
    targetConfiguration: PropTypes.object,
    targetNames: PropTypes.array,

    // functions
    handleClose: PropTypes.func,
    handleConfirm: PropTypes.func,
    handleChangeInput: PropTypes.func,
    handleDashboardTypeChange: PropTypes.func,
    handleChangeProperty: PropTypes.func,
    handleBackendSelect: PropTypes.func,
};

export default withStyles(styles)(DashboardModal);

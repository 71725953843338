import React, { useState } from "react";
import SlackIntegration from "types/model/settings/integration/SlackIntegration";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "components/common/Switch/Switch";
import TextField from "components/common/TextField/TextField";
import SubmitButton from "components/common/Button/SubmitButton";
import useIntegrationSectionState from "hooks/integration/useIntegrationSectionState";

interface SlackIntegrationSectionProps {
    integration: SlackIntegration;
    onSave: (integration: SlackIntegration) => void;
    onTest: (integration: SlackIntegration) => void;
}
interface ErrorMessages {
    botToken: string | null;
    channels: string | null;
}
const SlackIntegrationSection: React.FC<SlackIntegrationSectionProps> = ({
                                                                             onSave,
                                                                             onTest,
                                                                             ...props
                                                                         }) => {
    const { integration, handleChangeProperty, handleEnabledToggle }
        = useIntegrationSectionState(props.integration);
    const { enabled, botToken, channels } = integration as SlackIntegration;
    const [errors, setErrors] = useState<ErrorMessages>({
        botToken: null,
        channels: null,
    });
    const handleSave = () => {
        let hasError = false;
        const newErrors: ErrorMessages = { botToken: null, channels: null };
        if (enabled) {
            if (!botToken) {
                newErrors.botToken = "Bot Token cannot be empty.";
                hasError = true;
            }
            if (!channels) {
                newErrors.channels = "Channels cannot be empty.";
                hasError = true;
            }
            setErrors(newErrors);
        }
        if (!hasError) {
            newErrors.botToken = "";
            setErrors(newErrors);
            onSave(integration as SlackIntegration);
        }
    };
    return (
        <>
            <Grid
                container
                spacing={2}
                direction="column"
                justifyContent="flex-start"
                style={{marginTop: "8px", marginBottom: "8px"}}
            >
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="enable">Enabled</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <Switch checked={enabled || false}
                                onChange={handleEnabledToggle}
                                name="enabled"
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="botToken">Bot Token</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            disabled={!enabled}
                            name="botToken"
                            fullWidth
                            textAlign="left"
                            value={botToken}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                        {enabled && errors.botToken && <div style={{ color: 'red', marginTop: '4px', textAlign: 'left' }}>{errors.botToken}</div>}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="channels">Channels</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            disabled={!enabled}
                            name="channels"
                            fullWidth
                            textAlign="left"
                            value={channels}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                        {enabled && errors.channels && <div style={{ color: 'red', marginTop: '4px', textAlign: 'left' }}>{errors.channels}</div>}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={7}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center">
                    <Grid item>
                            {/* @ts-ignore */}
                            <SubmitButton  disabled={!enabled} onClick={() => onTest(integration)}>Test</SubmitButton>
                    </Grid>
                    <Grid item xs={1}>
                        {/*@ts-ignore*/}
                        <SubmitButton  onClick={handleSave}>Save</SubmitButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default SlackIntegrationSection;

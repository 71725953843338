import React from "react";
import ApplicationPage from "components/common/ApplicationPage/ApplicationPage";
import InfrastructureLeftNavBar from "components/gate/InfrastructureLeftNavBar";
import UpdatesContent from "./UpdatesContent";

const Updates: React.FC = () => (
    <ApplicationPage
        leftNavBarComponent={<InfrastructureLeftNavBar />}
        contentComponent={<UpdatesContent />}
    />
)

export default Updates;

import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "components/common/Switch/Switch";
import TextField from "components/common/TextField/TextField";
import SubmitButton from "components/common/Button/SubmitButton";
import useIntegrationSectionState from "hooks/integration/useIntegrationSectionState";
import GenAIIntegration from "types/model/settings/integration/GenAIIntegration";


interface GenAIIntegrationsProps {
    integration: GenAIIntegration;
    onSave: (integration: GenAIIntegration) => void;
}
interface ErrorMessages {
    aiKey: string | null;
    modalName: string | null;
}
const GenAiIntegrationSection: React.FC<GenAIIntegrationsProps> = ({
    onSave,
    ...props
}) => {
    const { integration, handleChangeProperty, handleEnabledToggle } = useIntegrationSectionState(props.integration);
    const { enabled, aiKey, modalName } = integration as GenAIIntegration;
    const [errors, setErrors] = useState<ErrorMessages>({
        aiKey: null,
        modalName: null,
    });
    const handleSave = () => {
        let hasError = false;
        const newErrors: ErrorMessages = { aiKey: null, modalName: null };
        if (enabled) {
            if (!aiKey) {
                newErrors.aiKey = "AI Key cannot be empty.";
                hasError = true;
            }
            if (!modalName) {
                newErrors.modalName = "Modal Name cannot be empty.";
                hasError = true;
            }
            setErrors(newErrors);
        }
        if (!hasError) {
            newErrors.aiKey = "";
            setErrors(newErrors);
            onSave(integration as GenAIIntegration);
        }
    };
    return (
        <Grid container spacing={2} direction="column" justifyContent="flex-start" style={{ marginTop: "8px", marginBottom: "8px" }}>
            <Grid
                item
                container
                spacing={2}
                xs={12}
                direction="row"
                justifyContent="flex-start"
                alignItems="center">
                <Grid item xs={2} style={{ textAlign: "left" }}>
                    <InputLabel htmlFor="enable">Enabled</InputLabel>
                </Grid>
                <Grid item xs={5}>
                    <Switch checked={enabled || false}
                        onChange={handleEnabledToggle}
                        name="enabled"
                    />
                </Grid>
            </Grid>
            <Grid item container spacing={2} xs={12} direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={2} style={{ textAlign: "left" }}>
                    <InputLabel htmlFor="apiKey">AI Key</InputLabel>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        disabled={!enabled}
                        name="aiKey"
                        fullWidth
                        textAlign="left"
                        value={aiKey}
                        onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                    />
                    {enabled && errors.aiKey && <div style={{ color: 'red', marginTop: '4px', textAlign: 'left' }}>{errors.aiKey}</div>}
                </Grid>
            </Grid>
            <Grid item container spacing={2} xs={12} direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={2} style={{ textAlign: "left" }}>
                    <InputLabel htmlFor="modalName">Modal Name</InputLabel>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        disabled={!enabled}
                        name="modalName"
                        fullWidth
                        textAlign="left"
                        value={modalName || ""}
                        onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                    />
                    {enabled && errors.modalName && <div style={{ color: 'red', marginTop: '4px', textAlign: 'left'}}>{errors.modalName}</div>}
                </Grid>
            </Grid>
            
            <Grid
                item
                container
                spacing={2}
                xs={7}
                direction="row"
                justifyContent="flex-end"
                alignItems="center">
                <Grid item xs={1}>
                    {/*@ts-ignore*/}
                    <SubmitButton onClick={handleSave}>Save</SubmitButton>
                </Grid>
            </Grid>
            
        </Grid>
    );
};

export default GenAiIntegrationSection;

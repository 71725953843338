import {publish} from "./hlcCommonWebSocketClient";
import {
    WEB_SOCKET_ACTION_PREFIX,
    WEB_SOCKET_SUBSCR_PREFIX
} from "constants/webSocketConstants";
import SaveIntegrationStompFrame from "types/payload/settings/integrations/SaveIntegrationStompFrame";
import TestIntegrationConfigStompFrame from "types/payload/settings/integrations/TestIntegrationConfigStompFrame";

const LIST_INTEGRATIONS_SETTINGS_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.settings.integration.list.execute`;
export const LIST_INTEGRATIONS_SETTINGS_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.settings.integration.list`;

export const listIntegrations = (appState: any, backendKey: string) =>
    publish(appState, backendKey, LIST_INTEGRATIONS_SETTINGS_ACTION, {
        empty: true
    });

const SAVE_INTEGRATION_SETTINGS_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.settings.integration.save.execute`;
export const SAVE_INTEGRATION_SETTINGS_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.settings.integration.save`;

export const TEST_INTEGRATION_CONFIG_URL_FOR_HLC =                    `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.hlc.test.integration`;
export const TEST_INTEGRATION_CONFIG_URL_FOR_HLC_SUBSCRIPTION =       `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.hlc.test.integration.result`;

export const saveIntegration = (appState: any, backendKey: string, payload: SaveIntegrationStompFrame) =>
    publish(appState, backendKey, SAVE_INTEGRATION_SETTINGS_ACTION, payload);


export const testIntegration = (appState: any, backendKey: string, payload: TestIntegrationConfigStompFrame) =>
    publish(appState, backendKey, TEST_INTEGRATION_CONFIG_URL_FOR_HLC, payload);

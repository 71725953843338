import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { selectDashlet, selectDashletData } from "reducers/dashboard/dashboardSlice";
import { selectHLCStompClient, selectIsHLCStompClientConnected } from "reducers/webSocketSlice";
import LoadingProgress from "components/dashboard/dashlets/LoadingProgress";
import * as _ from "lodash";
import { listTargetSpikes, refresh, setAlerts } from "../actions";
import SpikeSelectionDialog from "./SpikeSelectionDialog";
import HeatmapChart from "./HeatmapChart";
import {
    HEATMAP_LIST_TARGET_SPIKES_SUBSCRIPTION,
    HEATMAP_TARGET_ALERTS_SUBSCRIPTION,
    LOAD_HEATMAP_INITIAL_DATA_SUBSCRIPTION
} from "../webSocketClient";
import { openSpikePreview } from "reducers/dashboard/dashlets/heatmap/heatmapSpikePreviewSlice";
import { getUserSessionId } from 'actions/userInfoActions';
import HLCUserSessionWebSocketSubscription from 'websocket/HLCUserSessionWebSocketSubscription';
import WebSocketSubscription from 'websocket/WebSocketSubscription';
import { Typography } from '@material-ui/core';

const Heatmap = ({ dashlet, containerHeight, containerWidth }) => {
    const dashletId = dashlet.id;
    const data = useSelector(state => selectDashletData(state, dashletId));
    const backendKey = dashlet.config.backendKey;
    const stompClient = useSelector(selectHLCStompClient);
    const isStompClientConnected = useSelector(selectIsHLCStompClientConnected);
    const currentAlerts = data && data.alerts ? data.alerts : [];
    const [lastTargetSpikes, setLastTargetSpikes] = useState([]);
    const [selectSpikesOpened, setSelectSpikesOpened] = useState(false);
    const userSessionId = getUserSessionId();
    const dispatch = useDispatch();
    const isMounted = useRef(true);

    useEffect(() => {
        isMounted.current = true;

        const subscriptions = [];

        if (isStompClientConnected) {
            // const initialSubscription = stompClient.subscribe(
            //     `${LOAD_HEATMAP_INITIAL_DATA_SUBSCRIPTION}-${userSessionId}-${dashletId}`,
            //     (frame) => handleInitialAlertsResponse(JSON.parse(frame.body)),
            // );
            // dispatch(refresh(dashlet));
            // subscriptions.push(initialSubscription);

            const targetSpikesSubscription = stompClient.subscribe(
                `${HEATMAP_LIST_TARGET_SPIKES_SUBSCRIPTION}-${userSessionId}-${dashletId}`,
                (frame) => handleListTargetSpikesResponse(JSON.parse(frame.body)),
            );
            subscriptions.push(targetSpikesSubscription);

            // const alertsSubscription = stompClient.subscribe(
            //     `${HEATMAP_TARGET_ALERTS_SUBSCRIPTION}-${userSessionId}-${backendKey}-${dashletId}`,
            //     (frame) => handleAlertsResponse(JSON.parse(frame.body)),
            // );
            // subscriptions.push(alertsSubscription);
        }

        return () => {
            isMounted.current = false;
            subscriptions.forEach(subscription => subscription.unsubscribe());
        };
    }, [isStompClientConnected, dispatch, dashletId, stompClient]);

    const handleAlertsResponse = (response, isInitialLoad) => (dispatch, getState) => {    
        const state = getState();
        const activeDashboardId = state.dashboard.activeDashboardId;
        const isSpikePreviewOpened = state.heatmapSpikePreview.isSpikePreviewOpened;
        if (activeDashboardId === dashlet.dashboardId && !isSpikePreviewOpened)
            dispatch(setAlerts(dashlet, response, isInitialLoad));
    };

    const handleInitialAlertsResponse = response => {
        dispatch(handleAlertsResponse(response, true));
    }

    const handleClickOnTarget = (dashlet, targetName) => {
        dispatch(listTargetSpikes(dashlet, targetName, 40));
    }

    const openPreview = (targetName, timestamp) => {
        dispatch(openSpikePreview(targetName, timestamp));
    }

    const handleSelectTargetSpike = (targetName, timestamp) => {
        setSelectSpikesOpened(false)
        openPreview(targetName, timestamp)
    }

    const handleListTargetSpikesResponse = response => {
        if (isMounted.current) {
            const lastTargetSpikes = response.lastTargetSpikes;
            if (lastTargetSpikes.length > 0) {
                setLastTargetSpikes(lastTargetSpikes);
                setSelectSpikesOpened(true);
            } else {
                openPreview(response.targetName, null);
            }
        }
    };

    return (
        <React.Fragment>
            {data ? (
                <HeatmapChart
                    alerts={currentAlerts}
                    height={containerHeight}
                    width={containerWidth}
                    dashlet={dashlet}
                    handleClickOnTarget={handleClickOnTarget}
                />
            ) : (
                <Typography variant="body2">No data to display</Typography>
            )}
            <SpikeSelectionDialog
                open={selectSpikesOpened}
                lastTargetSpikes={lastTargetSpikes}
                handleClose={() => setSelectSpikesOpened(false)}
                handleSelect={handleSelectTargetSpike}
            />
            {isStompClientConnected && [
            <HLCUserSessionWebSocketSubscription
                key={`hlc-ws-subscr-heatmap-dashlet-${dashletId}`}
                subscriptionDestination={LOAD_HEATMAP_INITIAL_DATA_SUBSCRIPTION}
                dashletId={dashletId}
                handleMessage={handleInitialAlertsResponse}
                onSubscribeCallback={() => dispatch(refresh(dashlet))}
            />,
            <WebSocketSubscription
                key={`hlc-ws-subscr-heatmap-dashlet-push-${dashletId}`}
                stompClient={stompClient}
                backendKey={backendKey}
                dashletId={dashletId}
                subscriptionDestination={HEATMAP_TARGET_ALERTS_SUBSCRIPTION}
                handleMessage={(response) => dispatch(handleAlertsResponse(response, false))}
            />
        ]}
        </React.Fragment>
    );
};

export default Heatmap;
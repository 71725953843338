import React, { useState } from "react";
import AppDynamicsIntegration from "types/model/settings/integration/AppDynamicsIntegration";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "components/common/Switch/Switch";
import TextField from "components/common/TextField/TextField";
import SubmitButton from "components/common/Button/SubmitButton";
import useIntegrationSectionState from "hooks/integration/useIntegrationSectionState";

interface AppDynamicsIntegrationSectionProps {
    integration: AppDynamicsIntegration;
    onSave: (integration: AppDynamicsIntegration) => void;
}
interface ErrorMessages {
    url: string | null;
    accountId: string | null;
    username: string | null;
    password: string | null;
}
const AppDynamicsIntegrationSection: React.FC<AppDynamicsIntegrationSectionProps> = ({
                                                                                         onSave,
                                                                                         ...props
                                                                                     }) => {
    const { integration, handleChangeProperty, handleEnabledToggle }
        = useIntegrationSectionState(props.integration);
    const {enabled, url, accountId, username, password
    } = integration as AppDynamicsIntegration;

    const [errors, setErrors] = useState<ErrorMessages>({
        url: null,
        accountId: null,
        username: null,
        password: null,
    });

    const handleSave = () => {
        let hasError = false;
        const newErrors: ErrorMessages = { url: null, accountId: null, username: null, password: null };
        if (enabled) {
            if (!url) {
                newErrors.url = "URL cannot be empty.";
                hasError = true;
            }
            if (!accountId) {
                newErrors.accountId = "Account ID cannot be empty.";
                hasError = true;
            }
            if (!username) {
                newErrors.username = "Username cannot be empty.";
                hasError = true;
            }
            if (!password) {
                newErrors.password = "Password cannot be empty.";
                hasError = true;
            }
            setErrors(newErrors);
        }
        if (!hasError) {
            newErrors.url = "";
            setErrors(newErrors);
            onSave(integration as AppDynamicsIntegration);
        }
    };
    return (
        <>
            <Grid
                container
                spacing={2}
                direction="column"
                justifyContent="flex-start"
                style={{marginTop: "8px", marginBottom: "8px"}}
            >
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="enable">Enabled</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <Switch checked={enabled || false}
                                onChange={handleEnabledToggle}
                                name="enabled"
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="url">URL</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            disabled={!enabled}
                            name="url"
                            fullWidth
                            textAlign="left"
                            value={url}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                          {enabled && errors.url && <div style={{ color: 'red', marginTop: '4px', textAlign: 'left' }}>{errors.url}</div>}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="accountId">Account ID</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            disabled={!enabled}
                            name="accountId"
                            fullWidth
                            textAlign="left"
                            value={accountId}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                         {enabled && errors.accountId && <div style={{ color: 'red', marginTop: '4px', textAlign: 'left' }}>{errors.accountId}</div>}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="username">Username</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                             disabled={!enabled}
                            name="username"
                            fullWidth
                            textAlign="left"
                            value={username}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                         {enabled && errors.username && <div style={{ color: 'red', marginTop: '4px', textAlign: 'left' }}>{errors.username}</div>}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="password">Password</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            disabled={!enabled}
                            name="password"
                            type="password"
                            fullWidth
                            textAlign="left"
                            value={password}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                         {enabled && errors.password && <div style={{ color: 'red', marginTop: '4px', textAlign: 'left' }}>{errors.password}</div>}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={7}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center">
                    <Grid item xs={1}>
                        {/*@ts-ignore*/}
                        <SubmitButton onClick={handleSave}>Save</SubmitButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default AppDynamicsIntegrationSection;

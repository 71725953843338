import React, {FC, useState} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import * as _ from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import { IconButton, Tooltip } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';

export interface DashletConfigDropdownOption {
    value: string;
    label: string;
    isSetIcon?: boolean;
}

interface Props {
    label: string;
    name: string;
    value: string;
    options: Array<DashletConfigDropdownOption>;
    disabled?: boolean;
    error?: boolean;
    onChange?: (
        event: React.ChangeEvent<{ name?: string; value: unknown; type?: string, valueAsNumber?: any }>
    ) => void;
}

const DashletConfigDropdown: FC<Props> = ({
                                              label,
                                              name,
                                              value,
                                              options,
                                              disabled,
                                              error,
                                              onChange
                                          }) => {
    const key = name;
    const labelId = `label-${key}`;
    const [open, setOpen] = useState(false);

    return <FormControl fullWidth error={error} margin="dense">
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
            data-testid={`dashlet-config-dropdown-${name}`}
            labelId={labelId}
            id={name}
            name={name}
            value={value ? value : ''}
            onChange={onChange}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            disabled={disabled}
        >
            <MenuItem key={`menu-item-empty-${key}`} value=""></MenuItem>
            {_.map(options, (option) => {
                return (
                    <MenuItem
                        data-testid={`dashlet-config-dropdown-option-${option.value}`}
                        key={`menu-item-${option.value}`}
                        value={option.value}
                    >
                        {option.label}
                        {open && option.isSetIcon ? ( <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%'}} >
                            <Tooltip title="Report Has Alert">
                                <IconButton aria-label="close" style={{ padding: '4px', fontSize: '18px' }}>
                                    <NotificationsIcon style={{ fontSize: 'inherit' }} />
                                </IconButton>
                            </Tooltip>
                            </div> ) : null}
                    </MenuItem>
                )
            })}
        </Select>
    </FormControl>;
};

export default DashletConfigDropdown;